import { h } from 'preact';
import { UserListDisplayProps } from './user-list-types';
import styles from './displays.module.scss';
import CollapseContent from '../../../../../helpers/collapse';
import Icon from '../../../../../helpers/icon';
import { UniqueKeyForm } from './unique-key-input';
import { motion } from 'framer-motion';

interface NumberSelectProps {
  options: number[];
}

export function NumberSelectUserField({
  field,
  options,
  label,
  onChange,
}: UserListDisplayProps & NumberSelectProps) {
  if (field.value.type !== 'select-number') {
    return null;
  }

  function _onChange(e: Event) {
    const target = e.target as HTMLSelectElement;

    field.value.value = +target.value;

    onChange(field.value);
  }

  function onKeyChange(newKey: string) {
    field.key = newKey;

    onChange(field.value, field.key);
  }

  return (
    <div className={styles.userListItemContainer}>
      <CollapseContent
        trigger={
          <label className="form-label">
            <Icon icon="caret-down" size={18} /> Field Key: {field.key}
          </label>
        }
      >
        <UniqueKeyForm value={field.key} onChange={onKeyChange} />
      </CollapseContent>
      <motion.div layout className="form-floating">
        <select
          className="form-select"
          value={field.value.value}
          onChange={_onChange}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <label>{label.text}</label>
      </motion.div>
    </div>
  );
}
