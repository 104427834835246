import { h } from 'preact';

interface PropTypes {
  size: number;
  icon: string;
  class?: string;
}

export default function Icon(props: PropTypes) {
  const { size, icon } = props;

  return (
    <svg
      class={`bi icon ${props.class}`}
      width={size}
      height={size}
      fill="currentColor"
    >
      <use
        xlinkHref={`bootstrap-icons.svg#${icon}`}
        href={`bootstrap-icons.svg#${icon}`}
      />
    </svg>
  );
}
