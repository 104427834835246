import { computePosition, flip, getScrollParents } from '@floating-ui/dom';
import {
  AnimatePresence,
  motion,
  useMotionValue,
  Variants,
} from 'framer-motion';
import { Fragment, h, VNode } from 'preact';
import { createPortal } from 'preact/compat';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { MouseEvent } from 'react';
import { injectProxyRef } from './inject-proxy-ref';

const portal = document.getElementById('menus') as HTMLDivElement;

const variants: Variants = {
  initial: {
    opacity: [0, 0.9, 1],
    scale: 0.7,
    pointerEvents: 'none',
    display: 'block',
    position: 'absolute',
  },
  in: {
    opacity: 1,
    scale: 1,
    pointerEvents: 'all',
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.1,
      duration: 0.3,
      type: 'spring',
      mass: 0.01,
      velocity: 18,
    },
  },
  out: {
    opacity: [1, 0.9, 0],
    scale: 0.7,
    pointerEvents: 'none',
    transition: {
      type: 'spring',
      staggerChildren: 0.02,
      staggerDirection: -1,
      duration: 0.3,
      mass: 0.05,
      velocity: 18,
    },
  },
};

const listVariants: Variants = {
  initial: {
    opacity: 0,
    scale: 0.7,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.7,
  },
};

interface MenuProps {
  id: string;
  children: VNode | null | undefined | Array<VNode | null | undefined>;
  trigger: VNode;
  className?: string;
  autoClose?: 'inside' | 'outside' | 'both';
}
export function Menu({
  id,
  children,
  autoClose = 'both',
  className,
  trigger,
}: MenuProps) {
  const [open, setOpen] = useState(false);
  const [exit, setExit] = useState(false);
  const [origin, setOrigin] = useState('top right');
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const triggerRef = useRef<HTMLElement>(null);
  const updatePosition = useCallback(
    (float: HTMLElement) => {
      return () => {
        if (triggerRef?.current) {
          computePosition(triggerRef.current, float, {
            placement: 'bottom-end',
            middleware: [flip()],
          }).then((pos) => {
            x.set(pos.x);
            y.set(pos.y);

            switch (pos.placement) {
              case 'bottom-end':
                setOrigin('top right');
                break;
              case 'bottom-start':
                setOrigin('top left');
                break;
              case 'top-end':
                setOrigin('bottom right');
                break;
              case 'top-start':
                setOrigin('bottom left');
                break;
            }
          });
        }
      };
    },
    [triggerRef, x, y]
  );

  const ref = useCallback(
    (node: HTMLUListElement | null) => {
      if (!node || !triggerRef?.current) {
        return;
      }

      const triggerNode = triggerRef?.current;

      updatePosition(node)();

      [...getScrollParents(triggerNode), ...getScrollParents(node)].forEach(
        (el) => {
          el.addEventListener('scroll', updatePosition(node), {
            passive: true,
          });
          el.addEventListener('resize', updatePosition(node), {
            passive: true,
          });
        }
      );

      return () => {
        [...getScrollParents(triggerNode), ...getScrollParents(node)].forEach(
          (el) => {
            el.removeEventListener('scroll', updatePosition(node));
            el.removeEventListener('resize', updatePosition(node));
          }
        );
      };
    },
    [triggerRef, updatePosition]
  );

  function openMenu() {
    setOpen(true);
    setExit(true);
  }

  function closeMenu() {
    setExit(false);
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    function click(e: Event) {
      if (autoClose === 'both') {
        closeMenu();
        return;
      }

      const target = e.target as HTMLElement | null;
      const list = portal.querySelector(`#${id}`);

      if (!target || !list) {
        return;
      }

      const eventIsChildOfList = list.contains(target);

      if (!eventIsChildOfList && autoClose === 'outside') {
        closeMenu();
      }
      if (eventIsChildOfList && autoClose === 'inside') {
        closeMenu();
      }
    }

    requestAnimationFrame(() => {
      document.addEventListener('click', click);
    });

    return () => {
      document.removeEventListener('click', click);
    };
  }, [autoClose, id, open]);

  useEffect(() => {
    if (!triggerRef?.current || open) {
      return;
    }
    const t = triggerRef.current;

    t.addEventListener('click', openMenu);

    return () => {
      t.removeEventListener('click', openMenu);
    };
  }, [open, triggerRef]);

  if (!open) {
    return injectProxyRef(trigger, triggerRef);
  }

  return (
    <Fragment>
      {injectProxyRef(trigger, triggerRef)}
      {createPortal(
        <AnimatePresence>
          {exit && (
            <motion.ul
              ref={ref}
              id={id}
              className={`dropdown-menu ${className}`}
              initial="initial"
              animate="in"
              exit="out"
              onAnimationComplete={(d) => {
                if (d === 'out') {
                  setOpen(false);
                }
              }}
              variants={variants}
              style={{
                x,
                y,
                transformOrigin: origin,
              }}
            >
              {children}
            </motion.ul>
          )}
        </AnimatePresence>,
        portal
      )}
    </Fragment>
  );
}

interface MenuItemProps {
  children: h.JSX.Element | string | null;
  onClick?: (event: MouseEvent) => void;
  className?: string;
}
export function MenuItem({
  children,
  onClick,
  className = 'dropdown-item',
}: MenuItemProps) {
  return (
    <motion.li className={className} variants={listVariants} onClick={onClick}>
      {children}
    </motion.li>
  );
}
