import { motion } from 'framer-motion';
import { h } from 'preact';
import { useRef } from 'preact/hooks';
import styles from './displays.module.scss';
import { UserListDisplayProps } from './user-list-types';

export function StringUserField({
  field,
  label,
  onChange,
}: UserListDisplayProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  if (field.value.type !== 'string') {
    return null;
  }

  function onInput() {
    const target = textareaRef.current;
    if (!target || field.value.value === target.value) {
      return;
    }

    field.value.value = target.value;

    onChange(field.value, field.key);
  }

  return (
    <motion.div layout className="form-floating">
      <textarea
        className={`${styles.textarea} form-control`}
        name="string"
        id="string"
        placeholder={label.text}
        ref={textareaRef}
        onBlur={onInput}
        value={field.value.value}
      />
      <label for="string">{label.text}</label>
    </motion.div>
  );
}
