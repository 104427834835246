import { onSnapshot, Timestamp } from 'firebase/firestore';
import { useEffect, useMemo, useReducer } from 'preact/hooks';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import {
  SheetVersion,
  Template,
} from '../../../../types/database/sheet-version';
import { CustomMap } from '../../../../types/game-structures';
import { generateId } from '../../../helpers/generateId';
import { useObservable } from '../../../helpers/observable-hook';
import SheetVersionService from '../../../services/sheet-version-service';
import { overlayUserListKeysListener } from '../../game-grid/sheet/use-overlay-data';
import { displayKeysListener } from './listeners';

export const sheetListener = new ReplaySubject<Template.Sheet>();
export const dispatchSheetActionListener = new Subject<SheetAction>();
export const settingsKeysListener = new BehaviorSubject<
  CustomMap<Template.Setting>
>({});
export const userListKeysListener = new BehaviorSubject<
  CustomMap<Template.UserListField>
>({});
export const registeredDisplaysListener = new BehaviorSubject<
  Map<string, Map<string, number>>
>(new Map());

export const totalColumns = 6;
export const rowHeight = 60;
export const columnWidth = 100;

export function calculateColumnWidth(width: number) {
  return Math.floor(width / totalColumns);
}

export function calculateLayoutHeight(span: number) {
  return Math.floor(span * rowHeight);
}

export function calculateLayoutWidth(span: number, colWidth: number) {
  return Math.min(
    Math.floor(span * colWidth),
    Math.floor(totalColumns * colWidth)
  );
}

const patchSheetVersionListener = new Subject<Template.Sheet>();

type SheetAction =
  | { type: 'set-sheet'; sheet: Template.Sheet }
  | {
      type: 'add-page';
      key: string;
      config: Template.PageConfig;
    }
  | {
      type: 'update-page';
      page: Template.Page;
    }
  | {
      type: 'remove-page';
      page: string;
    }
  | {
      type: 'add-setting';
      page: string;
      key: string;
      index: number;
      config: Template.SettingConfig;
    }
  | {
      type: 'update-setting';
      page: string;
      setting: Template.Setting;
    }
  | {
      type: 'remove-setting';
      page: string;
      setting: string;
    }
  | { type: 'order-pages'; order: string[] }
  | { type: 'order-layouts'; page: string; order: string[] }
  | { type: 'order-settings'; page: string; settings: string[] }
  | { type: 'order-displays'; page: string; layout: string; order: string[] }
  | {
      type: 'add-layout';
      page: string;
      column: Template.ColumnSpan;
      config: Template.LayoutConfig;
      displays?: CustomMap<Template.Display>;
    }
  | {
      type: 'update-layout';
      page: string;
      layout: Template.Layout;
    }
  | {
      type: 'remove-layout';
      page: string;
      layout: string;
    }
  | {
      type: 'add-display';
      page: string;
      layout: string;
      key: string;
      config: Template.FieldConfig;
      label: Template.Label;
      action: Template.Action;
      column: Template.ColumnSpan;
    }
  | {
      type: 'update-display';
      page: string;
      layout: string;
      display: Template.Display;
    }
  | {
      type: 'remove-display';
      page: string;
      layout: string;
      display: string;
    }
  | {
      type: 'update-user-list-row';
      page: string;
      layout: string;
      userListItem: Template.UserList;
    }
  | {
      type: 'remove-user-list-row';
      page: string;
      layout: string;
      userListItem: Template.UserList;
    }
  | {
      type: 'update-user-list-item';
      page: string;
      layout: string;
      userListItem: string;
      userListField: Template.UserListField;
    };

function sheetReducer(
  sheet: Template.Sheet,
  action: SheetAction
): Template.Sheet {
  switch (action.type) {
    case 'set-sheet': {
      updateDisplayKeys(action.sheet);
      updateSettingsKeys(action.sheet);
      return { ...action.sheet };
    }
    case 'add-page': {
      sheet.pages[action.key] = {
        id: action.key,
        page: action.config,
        layouts: {},
        index: Object.entries(sheet.pages).length,
      };
      break;
    }
    case 'update-page': {
      sheet.pages[action.page.id] = action.page;
      break;
    }
    case 'remove-page': {
      delete sheet.pages[action.page];
      break;
    }
    case 'add-setting': {
      const page = sheet.pages[action.page];

      if (page && page.page.type === 'settings') {
        const key = generateId();

        page.page.values[key] = {
          id: key,
          key: action.key,
          index: action.index,
          config: action.config,
        };
      }
      updateSettingsKeys(sheet);
      break;
    }
    case 'update-setting': {
      const page = sheet.pages[action.page];

      if (page && page.page.type === 'settings') {
        page.page.values[action.setting.id] = action.setting;
      }
      updateSettingsKeys(sheet);
      break;
    }
    case 'remove-setting': {
      const page = sheet.pages[action.page];

      if (page && page.page.type === 'settings') {
        delete page.page.values[action.setting];
      }
      updateSettingsKeys(sheet);
      break;
    }
    case 'order-pages': {
      sheet.pages = action.order.reduce<CustomMap<Template.Page>>(
        (acc, curr, index) => {
          const page = sheet.pages[curr];
          if (page) {
            page.index = index;
            acc[curr] = page;
          }
          return acc;
        },
        {}
      );
      break;
    }
    case 'order-layouts': {
      const page = sheet.pages[action.page];

      if (page) {
        page.layouts = action.order.reduce<CustomMap<Template.Layout>>(
          (acc, curr, index) => {
            const layout = page.layouts[curr];

            if (layout) {
              layout.index = index;
              acc[curr] = layout;
            }

            return acc;
          },
          {}
        );
      }
      break;
    }
    case 'order-settings': {
      const page = sheet.pages[action.page];

      if (page && page.page.type === 'settings') {
        page.page.values = action.settings.reduce<CustomMap<Template.Setting>>(
          (acc, curr, index) => {
            const setting =
              page.page.type === 'settings'
                ? page.page.values[curr]
                : undefined;

            if (setting) {
              setting.index = index;
              acc[curr] = setting;
            }

            return acc;
          },
          {}
        );
        for (let i = 0; i < action.settings.length; i++) {
          const each = action.settings[i] ?? '';
          const setting = page.page.values[each];

          if (setting) {
            setting.index = i;
          }
        }
      }
      break;
    }
    case 'order-displays': {
      const page = sheet.pages[action.page];

      if (page) {
        const layout = page.layouts[action.layout];

        if (layout) {
          layout.displays = action.order.reduce<CustomMap<Template.Display>>(
            (acc, curr, index) => {
              const display = layout.displays[curr];
              if (display) {
                display.index = index;
                acc[curr] = display;
              }

              return acc;
            },
            {}
          );
        }
      }
      break;
    }
    case 'add-layout': {
      const page = sheet.pages[action.page];

      if (page) {
        const key = generateId();

        // If there are displays provided, ensure that they have unique keys
        // so that we are not double updating fields
        let displays: CustomMap<Template.Display> = {};
        if (action.displays) {
          Object.entries(action.displays).forEach(
            ([displayKey, value], index) => {
              const id = generateId();
              const newKey = generateId();

              displays[id] = { ...value, id, key: newKey };
            }
          );
        }

        page.layouts[key] = {
          id: key,
          column: action.column,
          config: action.config,
          displays,
          index: Object.entries(page.layouts).length,
        };
      }
      break;
    }
    case 'update-layout': {
      const page = sheet.pages[action.page];

      if (page) {
        page.layouts[action.layout.id] = action.layout;
      }
      break;
    }
    case 'remove-layout': {
      const page = sheet.pages[action.page];

      if (page) {
        delete page.layouts[action.layout];
      }
      updateDisplayKeys(sheet);
      break;
    }
    case 'add-display': {
      const page = sheet.pages[action.page];

      if (page) {
        const layout = page.layouts[action.layout];

        if (layout) {
          const id = generateId();

          layout.displays[id] = {
            id,
            key: action.key,
            column: action.column,
            label: action.label,
            config: action.config,
            action: action.action,
            index: Object.entries(layout.displays).length,
          };

          if (layout.config.type === 'user-list' && layout.config.values) {
            for (const userListItem of Object.values(layout.config.values)) {
              const _id = generateId();

              userListItem.values[_id] = {
                id: _id,
                displayId: id,
                key: generateId(),
                value: action.config,
              };
            }
          }
        }
      }
      updateDisplayKeys(sheet);
      break;
    }
    case 'update-display': {
      const page = sheet.pages[action.page];

      if (page) {
        const layout = page.layouts[action.layout];

        if (layout) {
          layout.displays[action.display.id] = action.display;
        }
      }
      updateDisplayKeys(sheet);
      break;
    }
    case 'remove-display': {
      const page = sheet.pages[action.page];

      if (page) {
        const layout = page.layouts[action.layout];

        if (layout) {
          delete layout.displays[action.display];

          if (layout.config.type === 'user-list' && layout.config.values) {
            for (const userListItem of Object.values(layout.config.values)) {
              const field = Object.values(userListItem.values).find(
                (f) => f.displayId === action.display
              );

              if (field) {
                delete userListItem.values[field.id];
              }
            }
          }
        }
      }
      updateDisplayKeys(sheet);
      break;
    }
    case 'update-user-list-row': {
      const page = sheet.pages[action.page];

      if (page) {
        const layout = page.layouts[action.layout];

        if (
          layout &&
          layout.config.type === 'user-list' &&
          layout.config.values
        ) {
          layout.config.values[action.userListItem.id] = action.userListItem;
        }
      }
      updateDisplayKeys(sheet);
      break;
    }
    case 'remove-user-list-row': {
      const page = sheet.pages[action.page];

      if (page) {
        const layout = page.layouts[action.layout];

        if (
          layout &&
          layout.config.type === 'user-list' &&
          layout.config.values
        ) {
          delete layout.config.values[action.userListItem.id];
        }
      }
      updateDisplayKeys(sheet);
      break;
    }
    case 'update-user-list-item': {
      const page = sheet.pages[action.page];

      if (page) {
        const layout = page.layouts[action.layout];

        if (
          layout &&
          layout.config.type === 'user-list' &&
          layout.config.values
        ) {
          const userListItem = layout.config.values[action.userListItem];

          if (userListItem) {
            userListItem.values[action.userListField.id] = action.userListField;
          }
        }
      }
      updateDisplayKeys(sheet);
      break;
    }
  }

  patchSheetVersionListener.next(sheet);
  return sheet;
}

function updateDisplayKeys(sheet: Template.Sheet) {
  const newDisplayKeys: CustomMap<Template.Display> = {};
  const newUserListItems: CustomMap<Template.UserListField> = {};
  const newRegisteredDisplays: Map<string, Map<string, number>> = new Map();

  Object.values(sheet.pages).forEach((page) => {
    Object.values(page.layouts).forEach((layout) => {
      Object.values(layout.displays).forEach((display) => {
        newDisplayKeys[display.key] = display;
        newDisplayKeys[display.id] = display;

        if (layout.config.type !== 'user-list') {
          switch (display.config.type) {
            case 'number':
            case 'select-number':
            case 'boolean':
            case 'calculated': {
              if (!display.config.registeredDisplayId) {
                return;
              }
              if (
                newRegisteredDisplays.has(display.config.registeredDisplayId)
              ) {
                newRegisteredDisplays
                  .get(display.config.registeredDisplayId)
                  ?.set(display.id, +display.config.value);
              } else {
                const newMap = new Map();
                newMap.set(display.id, +display.config.value);
                newRegisteredDisplays.set(
                  display.config.registeredDisplayId,
                  newMap
                );
              }
            }
          }
        }
      });

      if (layout.config.type === 'user-list' && layout.config.values) {
        Object.values(layout.config.values).forEach((row) => {
          Object.values(row.values).forEach((item) => {
            const linkedDisplay = layout.displays[item.displayId];
            newUserListItems[item.key] = item;

            if (
              linkedDisplay &&
              'registeredDisplayId' in linkedDisplay.config &&
              linkedDisplay.config.registeredDisplayId &&
              row.enabled
            ) {
              switch (item.value.type) {
                case 'number':
                case 'select-number':
                case 'boolean':
                case 'calculated': {
                  if (
                    newRegisteredDisplays.has(
                      linkedDisplay.config.registeredDisplayId
                    )
                  ) {
                    newRegisteredDisplays
                      .get(linkedDisplay.config.registeredDisplayId)
                      ?.set(item.id, +item.value.value);
                  } else {
                    const newMap = new Map();
                    newMap.set(item.id, +item.value.value);
                    newRegisteredDisplays.set(
                      linkedDisplay.config.registeredDisplayId,
                      newMap
                    );
                  }
                }
              }
            }
          });
        });
      }
    });
  });

  displayKeysListener.next(newDisplayKeys);
  userListKeysListener.next(newUserListItems);
  registeredDisplaysListener.next(newRegisteredDisplays);
}

function updateSettingsKeys(sheet: Template.Sheet) {
  const page = sheet.pages.settings;
  if (page?.page.type !== 'settings') {
    return;
  }

  const newSettingsKeys: CustomMap<Template.Setting> = {};

  Object.values(page.page.values).forEach((setting) => {
    newSettingsKeys[setting.key] = setting;
  });

  settingsKeysListener.next(newSettingsKeys);
}

export function useUniqueKeys() {
  const displayKeys = useObservable(displayKeysListener);
  const settingsKeys = useObservable(settingsKeysListener);
  const userListKeys = useObservable(userListKeysListener);
  const overlayKeys = useObservable(overlayUserListKeysListener);

  return useMemo(
    () =>
      displayKeys && settingsKeys && userListKeys && overlayKeys
        ? [
            ...Object.values(displayKeys).map((d) => d.key),
            ...Object.values(userListKeys).map((u) => u.key),
            ...Object.values(settingsKeys).map((s) => s.key),
            ...Object.values(overlayKeys).map((o) => o.key),
          ]
        : [],
    [displayKeys, overlayKeys, settingsKeys, userListKeys]
  );
}

export function useNumberKeys(): Set<string> {
  const displayKeys = useObservable(displayKeysListener);
  const settingsKeys = useObservable(settingsKeysListener);
  const userListKeys = useObservable(userListKeysListener);
  const overlayKeys = useObservable(overlayUserListKeysListener);

  return useMemo(
    () =>
      displayKeys && settingsKeys && userListKeys && overlayKeys
        ? new Set([
            ...Object.values(displayKeys)
              .filter(
                (d) =>
                  d.config.type === 'number' ||
                  d.config.type === 'select-number' ||
                  d.config.type === 'calculated' ||
                  d.config.type === 'boolean'
              )
              .map((d) => d.key),
            ...Object.values(userListKeys)
              .filter(
                ({ value }) =>
                  value.type === 'number' ||
                  value.type === 'select-number' ||
                  value.type === 'calculated' ||
                  value.type === 'boolean'
              )
              .map((u) => u.key),
            ...Object.values(overlayKeys)
              .filter(
                ({ value }) =>
                  value.type === 'number' ||
                  value.type === 'select-number' ||
                  value.type === 'calculated' ||
                  value.type === 'boolean'
              )
              .map((u) => u.key),
            ...Object.values(settingsKeys)
              .filter((s) => s.config.type === 'number')
              .map((s) => s.key),
          ])
        : new Set(),
    [displayKeys, overlayKeys, settingsKeys, userListKeys]
  );
}

export function useSheet(characterSheetId?: string, versionId?: string) {
  const sheetVersionService = useMemo(
    () => new SheetVersionService(characterSheetId ?? ''),
    [characterSheetId]
  );
  const [sheet, dispatchSheet] = useReducer(sheetReducer, {
    pages: {},
  });

  useEffect(() => {
    const s = dispatchSheetActionListener.subscribe(dispatchSheet);

    return () => {
      s.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!versionId) {
      return;
    }

    const s = patchSheetVersionListener.subscribe((sheetVersion) => {
      sheetVersionService
        .patch(versionId, {
          sheet: sheetVersion,
          updated: Timestamp.now(),
        })
        .then()
        .catch(console.log);
    });

    return () => {
      s.unsubscribe();
    };
  }, [sheetVersionService, versionId]);

  useEffect(() => {
    if (!versionId) {
      return;
    }
    const s = onSnapshot(sheetVersionService.getDoc(versionId), (snapshot) => {
      const data = snapshot.data() as SheetVersion | undefined;
      if (data && data.sheet) {
        dispatchSheet({ type: 'set-sheet', sheet: data.sheet });
      }
    });

    return () => {
      s();
    };
  }, [versionId, sheetVersionService]);

  useEffect(() => {
    sheetListener.next(sheet);
  }, [sheet]);
}
