import { ToastConfig } from '../components/toast/toast-list';
import { toastsListener } from '../game-services/listeners-service';

let _toasts: ToastConfig[] = [];

toastsListener.subscribe((toasts) => (_toasts = toasts));

export class ToastService {
  add(toast: ToastConfig) {
    if (
      _toasts.some(
        (t) => t.linkText === toast.linkText && t.href === toast.href
      )
    ) {
      return;
    }

    toastsListener.next([..._toasts, toast]);
  }

  remove(id: string) {
    toastsListener.next(_toasts.filter((t) => t.id !== id));
  }
}

export default new ToastService();
