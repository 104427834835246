import { matchSorter } from 'match-sorter';
import { Fragment, h } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import textarea_caret from 'textarea-caret';
import { Template } from '../../../../../../types/database/sheet-version';
import { useObservable } from '../../../../../helpers/observable-hook';
import { settingsKeysListener, useNumberKeys } from '../../grid-sort';
import { displayKeysListener } from '../../listeners';
import { PreviewDisplayProps } from '../../preview/displays/preview-types';
import { AutocompleteDropdown, Caret } from './autocomplete';
import { evaluate } from './evaluate';
import { DiceRollResult } from './input-types';
import styles from './inputs.module.scss';
import { RollButton } from './roll';

const breakRegex = new RegExp('[\\s|+|\\-|%|/|*|(|)]', 'g');

function getAutocompleteQuery(value: string, cursorIndex: number) {
  let back = '';
  let front = '';

  let i = cursorIndex - 1;
  const c = value[i];

  if (c && breakRegex.test(c)) {
    i--;
  }

  while (true) {
    const character = value[i];

    if (character && breakRegex.test(character)) {
      break;
    }

    if (character === '$') {
      back = value.substring(i, cursorIndex);
      break;
    }

    if (!character) {
      break;
    }

    i--;
  }
  let j = cursorIndex;
  while (true) {
    const each = value[j];

    if (!each || breakRegex.test(each)) {
      front = value.substring(cursorIndex, j);
      break;
    }

    j++;
  }

  return {
    query: `${back}${front}`,
    start: i + 1,
    end: j,
  };
}

interface CalculatedInputProps {
  action: Template.Action;
  hideTotal?: boolean;
  rollDice?: (rollResult: DiceRollResult) => void;
  showRollModal?: boolean;
}

export function CalculatedInput({
  displayKey,
  config,
  action,
  label,
  hideTotal,
  onChange,
  rollDice,
  showRollModal = false,
}: CalculatedInputProps & PreviewDisplayProps) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [cursorLocation, setCursorLocation] = useState(0);
  const [expressionTotal, setExpressionTotal] = useState<number>();
  const [options, setOptions] = useState<string[]>([]);
  const [isValidExpression, setIsValidExpression] = useState(true);
  const autocompleteRef = useRef<HTMLUListElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [caret, setCaret] = useState<Caret>({ top: 0, left: 0, height: 0 });
  const displayKeys = useObservable(displayKeysListener);
  const settingsKeys = useObservable(settingsKeysListener);
  const keys = useNumberKeys();

  useEffect(() => {
    if (config.type !== 'calculated') {
      return;
    }

    const { isValid, rollResult } = evaluate(displayKey, config.value);
    setIsValidExpression(isValid);
    setExpressionTotal(rollResult?.total);
  }, [config, displayKey, keys]);

  const setAutocompleteCoords = useCallback((target: HTMLTextAreaElement) => {
    const coords = textarea_caret(target, target.selectionStart);
    setCaret(coords);
  }, []);

  const onInput = useCallback(() => {
    const target = textareaRef.current;
    if (!target) {
      return;
    }

    config.value = target.value;

    onChange(config);
  }, [config, onChange]);

  const completeWord = useCallback(
    (
      index: number,
      queryResult: {
        query: string;
        start: number;
        end: number;
      }
    ) => {
      const target = textareaRef.current;
      const selectedOption = options[index];
      if (!target || !selectedOption) {
        return cursorLocation;
      }

      const front = target.value.slice(0, queryResult.start);
      const back = target.value.slice(queryResult.end);

      target.value = (front + selectedOption + back).trim();
      onInput();
      return front.length + selectedOption.length;
    },
    [cursorLocation, onInput, options]
  );

  const onTextareaKeyUp = useCallback(
    (e: KeyboardEvent) => {
      const target = textareaRef.current;
      if (!target) {
        return;
      }

      const value = target.value;
      const queryResult = getAutocompleteQuery(value, target.selectionStart);
      const character = value[target.selectionStart - 1];

      if (!displayKeys || !settingsKeys) {
        return;
      }

      if (character === '$') {
        setOpen(true);
      }

      switch (e.code) {
        case 'Escape':
          setOpen(false);
          return;
        case 'ArrowDown': {
          if (textareaRef.current && open) {
            setCursorLocation(textareaRef.current.selectionStart);
            autocompleteRef.current?.focus();
          }
          return;
        }
        case 'Space':
          if (e.ctrlKey || e.metaKey) {
            e.preventDefault();
            e.stopImmediatePropagation();

            if (queryResult.query.startsWith('$')) {
              setOpen(true);
            }
          }
          break;
        case 'Enter':
          if (e.metaKey || e.ctrlKey || open) {
            e.preventDefault();
            e.stopImmediatePropagation();
            const newCursorLocation = completeWord(0, queryResult);

            requestAnimationFrame(() => {
              textareaRef.current?.setSelectionRange(
                newCursorLocation,
                newCursorLocation
              );
            });
            setOpen(false);
          }
          return;
        case 'Backspace': {
          if (open && !queryResult.query) {
            setOpen(false);
          }
        }
      }

      if (!character || breakRegex.test(character)) {
        setOpen(false);
        return;
      }

      setAutocompleteCoords(target);

      const result = matchSorter(
        Array.from(keys),
        queryResult.query.replace('$', '')
      );
      setOptions(result);
    },
    [completeWord, displayKeys, keys, open, setAutocompleteCoords, settingsKeys]
  );

  const onAutocompleteKeydown = useCallback(
    (e: KeyboardEvent) => {
      const target = textareaRef.current;
      if (!target) {
        return;
      }

      const value = target.value;
      const query = getAutocompleteQuery(value, target.selectionStart);

      switch (e.key) {
        case 'ArrowDown':
          setSelected(Math.min(selected + 1, options.length - 1));
          break;
        case 'ArrowUp': {
          const next = Math.max(selected - 1, 0);

          if (selected === next) {
            textareaRef.current?.focus();
            requestAnimationFrame(() => {
              textareaRef.current?.setSelectionRange(
                cursorLocation,
                cursorLocation
              );
            });
          } else {
            setSelected(next);
          }
          break;
        }
        case 'Enter': {
          const newCursorLocation = completeWord(selected, query);

          textareaRef.current?.focus();
          requestAnimationFrame(() => {
            textareaRef.current?.setSelectionRange(
              newCursorLocation,
              newCursorLocation
            );
          });
          setOpen(false);
          break;
        }
      }
    },
    [completeWord, cursorLocation, options.length, selected]
  );

  const autocompleteClick = useCallback(
    (index: number) => {
      return () => {
        const target = textareaRef.current;
        if (!target) {
          return;
        }

        const value = target.value;
        const query = getAutocompleteQuery(value, target.selectionStart);

        completeWord(index, query);
        setOpen(false);
      };
    },
    [completeWord]
  );

  if (config.type !== 'calculated') {
    return null;
  }

  return (
    <Fragment>
      <div className={`${styles.calculated} form-floating`}>
        <textarea
          className={`${styles.textarea} ${
            isValidExpression ? 'is-valid' : 'is-invalid'
          } form-control`}
          placeholder="Equation"
          id="equation"
          onKeyUp={onTextareaKeyUp}
          ref={textareaRef}
          onInput={onInput}
          value={config.value}
          readonly={config.readonly}
          tabIndex={config.readonly ? -1 : 0}
        />
        <label for="equation">
          {!label.text ? 'Equation' : label.text}&nbsp;
          {isValidExpression ? 'is valid' : 'is invalid'}&nbsp;
          {!hideTotal &&
            !isNaN(expressionTotal ?? 0) &&
            `(Result: ${expressionTotal})`}
        </label>
        {action.type === 'roll' && (
          <RollButton
            displayKey={displayKey}
            value={config.value}
            rollDice={rollDice}
            showRollModal={showRollModal}
            setExpressionTotal={setExpressionTotal}
            setIsValidExpression={setIsValidExpression}
          />
        )}
        <AutocompleteDropdown
          open={open}
          onAutocompleteKeydown={onAutocompleteKeydown}
          autocompleteClick={autocompleteClick}
          autocompleteRef={autocompleteRef}
          selected={selected}
          options={options}
          caret={caret}
          triggerRef={textareaRef}
        />
      </div>
    </Fragment>
  );
}
