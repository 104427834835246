import { RestService } from './rest-service';
import { Game } from '../../types/database/game';

export class GameService extends RestService<Game> {
  constructor() {
    super('games');
  }
}

export default new GameService();
