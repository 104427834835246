import { h } from 'preact';
import { useRef } from 'preact/hooks';
import { onFocus } from '../../../../../helpers/input-helpers';
import { PreviewDisplayProps } from './preview-types';

export function NumberPreview({
  config,
  label,
  onChange,
}: PreviewDisplayProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  if (config.type !== 'number') {
    return null;
  }

  function onInput() {
    const target = inputRef.current;

    if (!target || +target.value === config.value) {
      return;
    }

    config.value = +target.value;

    onChange(config);
  }

  return (
    <div className="form-floating">
      <input
        className="form-control"
        placeholder={label.text}
        id="number"
        type="number"
        ref={inputRef}
        onBlur={onInput}
        onFocus={onFocus}
        value={config.value}
      />
      <label for="number">{label.text}</label>
    </div>
  );
}
