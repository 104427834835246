import { Template } from '../../../../types/database/sheet-version';

type GetPageProps = Template.Page;
export function getPageName({ page }: GetPageProps) {
  switch (page.type) {
    case 'container':
      return page.name;
    case 'settings':
      return 'Settings';
    case 'header':
      return 'Header';
  }
}

export function getLayoutName(layout: Template.Layout | undefined) {
  if (!layout) return '';

  return layout.config.name || layout.id;
}
