import { CharacterSheet } from '../../types/database/character-sheet';
import { RestService } from './rest-service';

export class CharacterSheetService extends RestService<CharacterSheet> {
  constructor() {
    super('character_sheets');
  }
}

export default new CharacterSheetService();
