import { motion } from 'framer-motion';
import { h } from 'preact';
import { useRef } from 'preact/hooks';
import styles from './displays.module.scss';
import { PreviewDisplayProps } from './preview-types';

export function StringPreview({
  config,
  label,
  onChange,
}: PreviewDisplayProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  if (config.type !== 'string') {
    return null;
  }

  function onInput() {
    const target = textareaRef.current;
    if (!target || config.value === target.value) {
      return;
    }

    config.value = target.value;

    onChange(config);
  }

  return (
    <motion.div layout className="form-floating">
      <textarea
        className={`${styles.textarea} form-control`}
        name="string"
        id="string"
        placeholder={label.text}
        ref={textareaRef}
        onBlur={onInput}
        value={config.value}
      />
      <label for="string">{label.text}</label>
    </motion.div>
  );
}
