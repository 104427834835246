import { queryUsernames } from '../http-queries';

const breakRegex = new RegExp('[\\s|+|\\-|%|/|*|(|)$]', 'g');

export async function uniqueUsername(
  username: string,
  id: string,
  errors: any
) {
  try {
    const res = await queryUsernames(username);

    const data = res.data;
    if (data.id !== id) {
      errors.username = 'Username already in use';
    }
  } catch (error) {
    console.log(error);
  }
}

export function uniqueKey(keys: string[], key: string, initialKey: string) {
  let err = {};
  const compareKey = key.trim();
  const compareKeys = keys.filter((i) => i !== initialKey);
  const duplicates = compareKeys.filter((i) => i === compareKey);

  if (duplicates.length > 0) {
    err = Object.assign(err, {
      key: 'Keys must be unique in settings and displays',
    });
  }

  if (breakRegex.test(key)) {
    err = Object.assign(err, {
      key: 'No usage of +,-,%,/,*,(,),$ or spaces in keys',
    });
  }

  return err;
}
