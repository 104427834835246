import { h } from 'preact';
import { PreviewDisplayProps } from './preview-types';

export function BooleanPreview({
  config,
  label,
  onChange,
}: PreviewDisplayProps) {
  if (config.type !== 'boolean') {
    return null;
  }

  function _onChange(e: Event) {
    const target = e.target as HTMLInputElement;

    config.value = target.checked;

    onChange(config);
  }

  return (
    <div class="form-check form-switch">
      <label class="form-check-label">
        <input
          class="form-check-input"
          type="checkbox"
          checked={config.value}
          onChange={_onChange}
        />
        {label.text}
      </label>
    </div>
  );
}
