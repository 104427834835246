import { useContext } from 'preact/hooks';
import { createContext } from 'react';
import { Game } from '../../../../types/database/game';
import { UserCharacter } from '../../../../types/database/user-character';

type ContextValue = {
  game: Game;
  gameId: string;
  character: UserCharacter;
  characterId: string;
};
type ContextType = undefined | ContextValue;
export const SheetContext = createContext<ContextType>(undefined);

export const useSheetContext = () => {
  return useContext(SheetContext);
};
