import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { toastsListener } from '../../game-services/listeners-service';
import ToastItem from './toast-item';
import styles from './toast-list.module.scss';

export interface ToastConfig {
  id: string;
  title: string;
  description: string;
  href: string;
  linkText: string;
  autoHide?: boolean;
  imgPath?: string;
  altText?: string;
  isClosable?: boolean;
}

export default function ToastList(props: any) {
  const [toasts, setToasts] = useState<ToastConfig[]>([]);

  useEffect(() => {
    const s = toastsListener.subscribe((t) => {
      setToasts(t);
    });

    return () => s.unsubscribe();
  }, []);

  return (
    <div class={styles.container} aria-live="polite" aria-atomic="true">
      <div className={styles.positioner}>
        <LayoutGroup>
          <AnimatePresence>
            {toasts.map((t) => (
              <ToastItem key={t.id} {...t} />
            ))}
          </AnimatePresence>
        </LayoutGroup>
      </div>
    </div>
  );
}
