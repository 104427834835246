import { GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import { firstValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { auth } from '../firebase';
import { authenticationStateListener } from '../game-services/listeners-service';

export class AuthenticationService {
  listenForUserLogin() {
    return authenticationStateListener.pipe(filter((user) => !!user));
  }

  listenForUserLogout() {
    return authenticationStateListener.pipe(filter((user) => !user));
  }

  getCurrentAuthenticationState() {
    return firstValueFrom(authenticationStateListener.pipe(take(1)));
  }

  login(provider: GoogleAuthProvider) {
    return signInWithRedirect(auth, provider);
  }

  logout() {
    return auth.signOut();
  }
}

export default new AuthenticationService();
