import { initializeApp } from 'firebase/app';
import {
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
} from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage, ref } from 'firebase/storage';

const app = initializeApp({
  apiKey: 'AIzaSyC2bi4JPOGr6Zvw7UF8EAJosnj_paG4IAw',
  authDomain: 'sbe.sh',
  databaseURL: 'https://game-grid-a25e6.firebaseio.com',
  projectId: 'game-grid-a25e6',
  storageBucket: 'game-grid-a25e6.appspot.com',
  messagingSenderId: '518113444866',
  appId: '1:518113444866:web:327ef607699016d9da531b',
  measurementId: 'G-KFEDYZZB35',
});

const db = getFirestore(app);
const functions = getFunctions(app);
const googleProvider = new GoogleAuthProvider();
const auth = getAuth(app);
const storage = getStorage(app);
const storageRef = ref(storage);

/* local-code */
connectFirestoreEmulator(db, 'localhost', 5002);
connectFunctionsEmulator(functions, 'localhost', 5001);
connectStorageEmulator(storage, 'localhost', 9199);
connectAuthEmulator(auth, 'http://localhost:9099', {
  disableWarnings: true,
});
/* end-local-code */

export { app, db, auth, googleProvider, storageRef as storage, functions };
