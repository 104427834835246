import { h } from 'preact';
import { UserListDisplayProps } from './user-list-types';

interface StringSelectProps {
  options: string[];
}

export function StringSelectUserField({
  field,
  options,
  label,
  onChange,
}: UserListDisplayProps & StringSelectProps) {
  if (field.value.type !== 'select-string') {
    return null;
  }

  function _onChange(e: Event) {
    const target = e.target as HTMLSelectElement;

    field.value.value = target.value;

    onChange(field.value);
  }

  return (
    <div className="form-floating">
      <select
        className="form-select"
        value={field.value.value}
        onChange={_onChange}
      >
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <label>{label.text}</label>
    </div>
  );
}
