import { useFormik } from 'formik';
import { AnimatePresence, LayoutGroup, motion, Reorder } from 'framer-motion';
import { h } from 'preact';
import { useRef } from 'preact/hooks';
import * as yup from 'yup';
import { Template } from '../../../../types/database/sheet-version';
import { DragRow } from '../../../helpers/drag-handle';
import { ErrorDisplay, isFieldValid } from '../../../helpers/errors';
import {
  formikPropsSubmitOnInput,
  formikValidateOnInput,
} from '../../../helpers/form-helpers';
import Icon from '../../../helpers/icon';
import { useObservable } from '../../../helpers/observable-hook';
import { uniqueKey } from '../../../helpers/validators';
import { overlayDataListener } from '../../game-grid/sheet/use-overlay-data';
import styles from './editor.module.scss';
import { dispatchSheetActionListener, useUniqueKeys } from './grid-sort';

interface SettingsListProps {
  page: Template.Page;
}
export function SettingsList({ page }: SettingsListProps) {
  const config = page.page;

  if (config.type !== 'settings') {
    return null;
  }

  const values = Object.entries(config.values).sort(
    ([, a], [, b]) => a.index - b.index
  );

  return (
    <motion.div
      layout
      layoutScroll
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.settings}
    >
      <LayoutGroup>
        <Reorder.Group
          axis="y"
          values={values.map(([_, value]) => value)}
          onReorder={(data: Template.Setting[]) => {
            dispatchSheetActionListener.next({
              type: 'order-settings',
              page: page.id,
              settings: data.map((each) => each.id),
            });
          }}
          className={`list-group list-group-flush`}
        >
          <AnimatePresence>
            {values.map(([key, value]) => (
              <Setting page={page} key={key} setting={value} />
            ))}
          </AnimatePresence>
        </Reorder.Group>
        <AddSetting page={page} />
      </LayoutGroup>
    </motion.div>
  );
}

interface SettingProps {
  page: Template.Page;
  setting: Template.Setting;
}
function Setting({ setting, page }: SettingProps) {
  const keys = useUniqueKeys();
  const overlayData = useObservable(overlayDataListener);

  const {
    submitForm,
    getFieldProps,
    validateForm,
    handleSubmit,
    touched,
    errors,
    values,
  } = useFormik<AddSettingForm>({
    validateOnBlur: false,
    initialValues: {
      key: setting.key,
      type: setting.config.type,
      value: setting.config.value,
    },
    validationSchema: yup.object({
      key: yup.string().required('Required'),
      type: yup.string().required('Required'),
      value: yup.mixed().required('Required'),
    }),
    validate: (v) => {
      let err = {};

      err = Object.assign(err, uniqueKey(keys, v.key, setting.key));

      return err;
    },
    onSubmit: (v) => {
      if (page.page.type !== 'settings') {
        return;
      }

      const newSetting = {
        ...setting,
        key: v.key,
        config: {
          type: v.type,
          value: v.value,
          adminOnly: true, // build a switch for this.
        },
      };

      dispatchSheetActionListener.next({
        type: 'update-setting',
        page: page.id,
        setting: newSetting,
      });
    },
  });

  function removeSetting() {
    dispatchSheetActionListener.next({
      type: 'remove-setting',
      page: page.id,
      setting: setting.id,
    });
  }

  return (
    <DragRow
      value={setting}
      id={setting.id}
      className={`${styles.row} list-group-item`}
      disabled={!!overlayData}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-floating">
          <input
            className={`form-control ${isFieldValid(
              { touched, errors },
              'key'
            )}`}
            autoComplete="off"
            spellCheck
            {...formikPropsSubmitOnInput({
              submitForm,
              getFieldProps,
              validateForm,
            })('key')}
          />
          <label htmlFor="key">Field Lookup Key</label>
          {ErrorDisplay(
            {
              touched,
              errors,
            },
            'key'
          )}
        </div>

        <div className="form-floating">
          <select
            className={`form-select ${isFieldValid(
              { touched, errors },
              'type'
            )}`}
            {...formikPropsSubmitOnInput({
              submitForm,
              getFieldProps,
              validateForm,
            })('type')}
          >
            <option value="number">Number</option>
            <option value="text">Text</option>
          </select>
          <label htmlFor="type">Field Type</label>
          {ErrorDisplay(
            {
              touched,
              errors,
            },
            'type'
          )}
        </div>

        <div className="form-floating">
          <input
            className={`form-control ${isFieldValid(
              { touched, errors },
              'value'
            )}`}
            type={values.type}
            autoComplete="off"
            {...formikPropsSubmitOnInput({
              submitForm,
              getFieldProps,
              validateForm,
            })('value')}
          />
          <label htmlFor="value">Field Value</label>
          {ErrorDisplay(
            {
              touched,
              errors,
            },
            'value'
          )}
        </div>

        <div>
          <button class="btn btn-danger" type="button" onClick={removeSetting}>
            <Icon icon="x-square" size={14} />
          </button>
        </div>
      </form>
    </DragRow>
  );
}

interface AddSettingForm {
  key: string;
  type: 'text' | 'number';
  value: string | number;
}

interface AddSettingProps {
  page: Template.Page;
}
function AddSetting({ page }: AddSettingProps) {
  const keys = useUniqueKeys();
  const firstFieldRef = useRef<HTMLInputElement>(null);

  const formik = useFormik<AddSettingForm>({
    validateOnChange: true,
    initialValues: {
      key: '',
      type: 'number',
      value: '',
    },
    validationSchema: yup.object({
      key: yup.string().required('Required'),
      type: yup.string().required('Required'),
      value: yup.mixed().required('Required'),
    }),
    validate: (values) => {
      let errors = {};

      errors = Object.assign(errors, uniqueKey(keys, values.key, ''));

      return errors;
    },
    onSubmit: (values) => {
      if (page.page.type !== 'settings') {
        return;
      }

      dispatchSheetActionListener.next({
        type: 'add-setting',
        index: Object.keys(page.page.values).length,
        key: values.key,
        page: page.id,
        config: {
          type: values.type,
          value: values.value,
          adminOnly: false, // add a switch for this
        },
      });

      formik.resetForm();
      firstFieldRef.current?.focus();
    },
  });

  return (
    <motion.footer
      layout
      className={`${styles.row} ${styles.footerRow} list-group-item`}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="form-floating">
          <input
            ref={firstFieldRef}
            spellCheck
            autocomplete="off"
            className={`form-control ${isFieldValid(formik, 'key')}`}
            {...formik.getFieldProps('key')}
            {...formikValidateOnInput(formik.getFieldProps)('key')}
          />
          <label htmlFor="key">Field Lookup Key</label>
          {ErrorDisplay(formik, 'key')}
        </div>

        <div className="form-floating">
          <select
            className={`form-select ${isFieldValid(formik, 'type')}`}
            {...formik.getFieldProps('type')}
          >
            <option value="number">Number</option>
            <option value="text">Text</option>
          </select>
          <label htmlFor="type">Field Type</label>
          {ErrorDisplay(formik, 'type')}
        </div>

        <div className="form-floating">
          <input
            type={formik.values.type}
            autocomplete="off"
            className={`form-control ${isFieldValid(formik, 'value')}`}
            {...formik.getFieldProps('value')}
          />
          <label htmlFor="value">Field Value</label>
          {ErrorDisplay(formik, 'value')}
        </div>

        <div>
          <button class="btn btn-primary" type="submit">
            <Icon icon="plus-square" size={14} />
          </button>
        </div>
      </form>
    </motion.footer>
  );
}
