import { h } from 'preact';

interface PropTypes {
  size?: number;
}

export default function Loading(props: PropTypes = { size: 3 }) {
  return (
    <div
      class="spinner-grow text-primary"
      style={`width: ${props.size}rem; height: ${props.size}rem;`}
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  );
}
