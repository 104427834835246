import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { BaseRest } from './base';

interface ICharacterSheet extends BaseRest {
  owner: string;
  name: string;
  tags: string[];
  public: boolean;
  contributors: string[];
}

export interface ICharacterSheetDisplay {
  doc: QueryDocumentSnapshot<DocumentData>;
  characterSheet: CharacterSheet;
}

export class CharacterSheet extends BaseRest implements ICharacterSheet {
  owner: string;
  name: string;
  tags: string[];
  public: boolean;
  contributors: string[];

  constructor(cs: ICharacterSheet) {
    super(cs);
    this.owner = cs.owner;
    this.name = cs.name;
    this.tags = cs.tags;
    this.public = cs.public;
    this.contributors = cs.contributors;
  }

  static getInitialForm() {
    return {
      ...super.getInitialForm(),
      owner: '',
      name: '',
      tags: [],
      public: false,
      contributors: [],
    } as CharacterSheet;
  }
}
