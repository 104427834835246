import { createContext } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { ReplaySubject } from 'rxjs';

const DEFAULT_THEME = 'light';
const Theme = createContext(DEFAULT_THEME);
const themeListener = new ReplaySubject<string>(1);

function useTheme() {
  const [theme, setTheme] = useState<string>(DEFAULT_THEME);

  useEffect(() => {
    const s = themeListener.subscribe((data) => {
      setTheme(data);
      console.log('set theme', data);
    });

    return () => s.unsubscribe();
  }, []);

  return {
    theme,
  };
}

export { Theme, useTheme, themeListener };
