import { h } from 'preact';

import { PreviewDisplayProps } from './preview-types';

interface NumberSelectProps {
  options: number[];
}

export function NumberSelectPreview({
  config,
  options,
  label,
  onChange,
}: PreviewDisplayProps & NumberSelectProps) {
  if (config.type !== 'select-number') {
    return null;
  }

  if (!options) {
    options = [];
  }

  function _onChange(e: Event) {
    const target = e.target as HTMLSelectElement;

    config.value = +target.value;

    onChange(config);
  }

  return (
    <div className="form-floating">
      <select className="form-select" value={config.value} onChange={_onChange}>
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <label>{label.text}</label>
    </div>
  );
}
