import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { User } from '../../types/database/user';
import { auth, db } from '../firebase';
import { FirebaseUser } from '../firebase-user';
import {
  authenticationStateListener,
  userDataListener,
} from '../game-services/listeners-service';
import { RestService } from './rest-service';

let _authUser: FirebaseUser | null;
export class UsersService extends RestService<User> {
  constructor() {
    super('users');

    authenticationStateListener.subscribe(async (state) => {
      _authUser = auth.currentUser;

      if (_authUser && state) {
        const _userDoc = doc(db, `users/${_authUser.uid}`);

        const dbUser = (await getDoc(_userDoc)).data() as User;

        if (dbUser) {
          const payload = {
            id: _authUser.uid,
            name: _authUser.displayName ?? '',
          } as User;

          await this.patch(_authUser.uid, payload);
        }

        userDataListener.next((await getDoc(_userDoc)).data() as User);
      } else {
        _authUser = null;
      }
    });

    onAuthStateChanged(auth, (user) => {
      if (user) {
        authenticationStateListener.next(user);
      } else {
        authenticationStateListener.next(null);
      }
    });
  }

  getLocallyStoredUserReference() {
    return firstValueFrom(userDataListener.pipe(take(1)));
  }
}

export default new UsersService();
