import { Timestamp } from 'firebase/firestore';
import { motion } from 'framer-motion';
import { marked } from 'marked';
import { h } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';
import { Template } from '../../../../../../types/database/sheet-version';
import { useUserDataEffect } from '../../../../../helpers/hooks';
import { useObservable } from '../../../../../helpers/observable-hook';
import ChatService from '../../../../../services/chat-service';
import { useSheetContext } from '../../../../game-grid/sheet/sheet-context';
import {
  dispatchOverlayDataActionListener,
  overlayDataListener,
} from '../../../../game-grid/sheet/use-overlay-data';
import { dispatchSheetActionListener } from '../../grid-sort';
import { CalculatedInput } from '../../sidebar/inputs/calculated';
import { DiceRollResult } from '../../sidebar/inputs/input-types';
import { BooleanUserField } from './boolean';
import styles from './displays.module.scss';
import { NumberUserField } from './number';
import { NumberSelectUserField } from './number-select';
import { StringUserField } from './string';
import { StringSelectUserField } from './string-select';
import { UserListRendererProps } from './user-list-types';

export function DisplayRenderer({
  page,
  layout,
  display,
  item,
  field,
}: UserListRendererProps) {
  const sheetContext = useSheetContext();
  const { user } = useUserDataEffect();
  const chatService = useMemo(
    () =>
      sheetContext?.gameId ? new ChatService(sheetContext?.gameId) : undefined,
    [sheetContext?.gameId]
  );
  const overlayData = useObservable(overlayDataListener);
  const onChange = useCallback(
    (config: Template.FieldValue, key?: string) => {
      field.value = {
        type: config.type,
        value: config.value,
      } as Template.FieldValue;

      if (key) {
        field.key = key;
      }

      if (overlayData) {
        dispatchOverlayDataActionListener.next({
          type: 'set-user-list-field',
          layout,
          userListKey: item.id,
          field,
        });
        return;
      }

      dispatchSheetActionListener.next({
        type: 'update-user-list-item',
        layout,
        page,
        userListItem: item.id,
        userListField: field,
      });
    },
    [field, item.id, layout, overlayData, page]
  );

  const rollDice = useCallback(
    (rollResult: DiceRollResult) => {
      const split = rollResult.output.split(':');
      const equation = split[1];

      if (!equation || !user?.id || !chatService) {
        return;
      }

      const value = {
        content: marked(
          `${display.label.text}: ${
            item.label.text
          } \n > ${equation.trim()} \n\n`
        ),
        userId: user.id,
        created: Timestamp.now(),
        updated: Timestamp.now(),
      };

      chatService.add(value);
    },
    [chatService, display.label.text, item.label.text, user?.id]
  );

  function getDisplay() {
    switch (field.value.type) {
      case 'number':
        return (
          <NumberUserField
            displayKey={display.key}
            label={display.label}
            field={field}
            onChange={onChange}
          />
        );
      case 'string':
        return (
          <StringUserField
            displayKey={display.key}
            label={display.label}
            field={field}
            onChange={onChange}
          />
        );
      case 'select-string':
        return (
          <StringSelectUserField
            displayKey={display.key}
            label={display.label}
            field={field}
            options={
              display.config.type === 'select-string'
                ? display.config.options
                : []
            }
            onChange={onChange}
          />
        );
      case 'select-number':
        return (
          <NumberSelectUserField
            displayKey={display.key}
            label={display.label}
            field={field}
            options={
              display.config.type === 'select-number'
                ? display.config.options
                : []
            }
            onChange={onChange}
          />
        );
      case 'boolean':
        return (
          <BooleanUserField
            displayKey={display.key}
            label={display.label}
            field={field}
            onChange={onChange}
          />
        );
      case 'calculated':
        return (
          <CalculatedInput
            displayKey={field.key}
            config={field.value}
            label={display.label}
            action={display.action}
            onChange={onChange}
            rollDice={overlayData && rollDice}
          />
        );
    }
  }

  return (
    <motion.div
      layout
      className={styles.display}
      style={{
        gridColumn: `span ${display.column.spanH}`,
        gridRow: `span ${display.column.spanV}`,
      }}
    >
      {getDisplay()}
    </motion.div>
  );
}
