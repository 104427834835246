import { h } from 'preact';
import { Link } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { Game } from '../../../types/database/game';
import Loading from '../../helpers/loading';
import BasePage from '../../helpers/page';
import gameService from '../../services/game-service';

export default function GamePage(props: any) {
  const [game, setGame] = useState<Game | undefined>(undefined);

  useEffect(() => {
    gameService
      .get(props.matches.id)
      .then((doc) => setGame(doc.data() as Game))
      .catch(console.log);
  }, [props.matches.id]);

  return (
    <BasePage {...props}>
      game id: {props.matches.id}
      <div>
        <Link href={`/games/${props.matches.id}/grid`}>Game Board</Link>
        {game ? (
          game.tags.map((tag) => <span key={tag}>{tag}</span>)
        ) : (
          <Loading />
        )}
      </div>
    </BasePage>
  );
}
