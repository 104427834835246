import { httpsCallable } from 'firebase/functions';
import { CharacterSheet } from '../types/database/character-sheet';
import { SheetVersion } from '../types/database/sheet-version';
import { User } from '../types/database/user';
import { functions } from './firebase';

export const queryUsernames = httpsCallable<unknown, User>(
  functions,
  'queryUsernames'
);

export const queryUsersInGame = httpsCallable<unknown, User[]>(
  functions,
  'queryUsersInGame'
);

export type QueryCharacterSheets = {
  sheet: CharacterSheet;
  sheetId: string;
  version: SheetVersion;
  versionId: string;
};
export const queryCharacterSheets = httpsCallable<
  unknown,
  QueryCharacterSheets
>(functions, 'queryCharacterSheets');
