import { AnimatePresence } from 'framer-motion';
import { h, render } from 'preact';
import Router, { route, Route, RouterOnChangeArgs } from 'preact-router';
import { lazy, Suspense, useEffect } from 'preact/compat';
import './app.scss';
import Header from './components/header/header';
import ToastList from './components/toast/toast-list';
import {
  authenticationStateListener,
  urlLocationListener,
} from './game-services/listeners-service';
import Loading from './helpers/loading';
import { Preview } from './pages/character-sheets/editor/preview';
import VersionList from './pages/character-sheets/versions/versions';
import GamePage from './pages/games/game';
import authenticationService from './services/authentication-service';
import userService from './services/user-service';
import { Theme, useTheme } from './theme';

const Landing = lazy(() => import('./pages/landing/landing'));
const SignUp = lazy(() => import('./pages/signup/signup'));
const Home = lazy(() => import('./pages/home/home'));
const Games = lazy(() => import('./pages/games/games'));
const NewGame = lazy(() => import('./pages/games/create/new'));
const SetupBoard = lazy(() => import('./pages/games/create/setup-board'));
const GameGrid = lazy(() => import('./pages/game-grid/game-grid'));
const GameSheet = lazy(() => import('./pages/game-grid/sheet/sheet'));
const Profile = lazy(() => import('./pages/profile/profile'));
const SheetEditor = lazy(
  () => import('./pages/character-sheets/editor/editor')
);
const SheetList = lazy(() => import('./pages/character-sheets/list/list'));

const container = document.querySelector('#body') as HTMLElement;
const protectedRoutes = [
  '/profile',
  '/games',
  '/home',
  '/character-sheets',
  '/sign_up',
];
let currentUrl = '';

function syncHeight() {
  document.documentElement.style.setProperty(
    'height',
    `${window.innerHeight}px`
  );
}

function App() {
  const { theme } = useTheme();

  useEffect(() => {
    syncHeight();
    window.addEventListener('resize', syncHeight, {
      passive: true,
    });

    return () => {
      window.removeEventListener('resize', syncHeight);
    };
  }, []);

  return (
    <Theme.Provider value={theme}>
      <Header />
      <section id="app">
        <AnimatePresence exitBeforeEnter>
          <Suspense fallback={<Loading />}>
            <Router onChange={handleRouteChange} static>
              <Landing path="/" />
              <SignUp path="/sign_up/:id" />
              <Home path="/home" />
              <Games path="/games" />
              <NewGame path="/games/new" />
              <SetupBoard path="/games/setup_board/:id" />
              <GamePage path="/games/:id" />
              <Profile path="/profile/:id" />

              <Route path="/games/:id/grid" component={GameGrid} />
              <Route path="/games/:id/grid/:pane?" component={GameGrid} />
              <Route
                path="/games/:id/grid/:pane/:boardId"
                component={GameGrid}
              />
              <Route
                path="/games/:id/grid/:pane/:boardId/:tab"
                component={GameGrid}
              />
              <Route
                path="/games/:id/sheets/:characterId"
                component={GameSheet}
              />
              <Route
                path="/games/:id/sheets/:characterId/:tab"
                component={GameSheet}
              />
              <Route path="/character-sheets" component={SheetList} />
              <Route
                path="/character-sheets/sheet-editor/:id"
                component={VersionList}
              />
              <Route
                path="/character-sheets/sheet-editor"
                component={SheetEditor}
              />
              <Route
                path="/character-sheets/sheet-editor/:id/:version"
                component={SheetEditor}
              />
              <Route
                path="/character-sheets/sheet-editor/:id/:version/:tab"
                component={SheetEditor}
              />
              <Route
                path="/character-sheets/preview/:id/:version"
                component={Preview}
              />
              <Route
                path="/character-sheets/preview/:id/:version/:tab"
                component={Preview}
              />
            </Router>
          </Suspense>
        </AnimatePresence>
      </section>
      <ToastList />
    </Theme.Provider>
  );
}

async function handleRouteChange(e: RouterOnChangeArgs) {
  currentUrl = e.url;
  const authState = await authenticationService.getCurrentAuthenticationState();
  let dispatchUrl = true;

  if (authState) {
    const dbUser = await userService.getLocallyStoredUserReference();

    if (!dbUser?.initialized && !currentUrl.startsWith('/sign_up/')) {
      route(`/sign_up/${authState.uid}`);
      dispatchUrl = false;
    }
  } else {
    dispatchUrl = protectRoutes(protectedRoutes, currentUrl);
  }

  if (dispatchUrl) {
    urlLocationListener.next(currentUrl);
  }
}

function protectRoutes(routes: string[], url: string) {
  if (routes.some((r: string) => url.startsWith(r))) {
    route('/', true);
    return false;
  }

  return true;
}

authenticationStateListener.subscribe((state) => {
  if (!state) {
    protectRoutes(protectedRoutes, currentUrl);
  }
});

render(<App />, container);
