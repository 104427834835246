import { Toast } from 'bootstrap';
import { motion } from 'framer-motion';
import { h } from 'preact';
import { Link } from 'preact-router';
import { useEffect, useRef, useState } from 'preact/hooks';
import toastService from '../../services/toast-service';
import { ToastConfig } from './toast-list';

interface ToastProps {
  close?: (t: ToastConfig) => void;
}

type PropTypes = ToastConfig & ToastProps;

export default function ToastItem({
  id,
  description,
  title,
  href,
  linkText,
  autoHide,
  imgPath,
  altText,
  isClosable,
  close,
}: PropTypes) {
  const ref = useRef<HTMLDivElement>(null);
  const [toast, setToast] = useState<Toast | undefined>(undefined);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const t = new Toast(ref.current, {
      autohide: !!autoHide,
    });
    setToast(t);
  }, [autoHide]);

  const onClose = () => {
    toastService.remove(id);
    if (close) {
      close({
        id,
        description,
        title,
        href,
        linkText,
        autoHide,
        imgPath,
        altText,
        isClosable,
      });
    }
  };

  const clickBody = (e: Event) => {
    e.preventDefault();
    onClose();
  };

  return (
    <motion.div
      ref={ref}
      id={id}
      className="toast show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      layout
      transition={{ type: 'spring', bounce: 0.25 }}
      initial={{ opacity: 0, y: 14 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{
        opacity: 0,
        y: 14,
        transition: {
          duration: 0.2,
        },
      }}
    >
      <div class="toast-header">
        <img src={imgPath} class="rounded me-2" alt={altText} />
        <strong class="me-auto">{title}</strong>
        <small class="text-muted">{description}</small>
        {isClosable && (
          <button
            onClick={onClose}
            type="button"
            class="ms-2 mb-1 btn-close"
            aria-label="Close"
          />
        )}
      </div>
      <div class="toast-body">
        <Link href={href} onClick={clickBody}>
          {linkText}
        </Link>
      </div>
    </motion.div>
  );
}
