import { StateUpdater, useEffect, useState } from 'preact/hooks';
import { User } from '../../types/database/user';
import {
  urlLocationListener,
  userDataListener,
} from '../game-services/listeners-service';
import authenticationService from '../services/authentication-service';

export function useUserDataEffect() {
  const [user, setUser] = useState<User | undefined>(undefined);
  useEffect(() => {
    const s = [
      userDataListener.subscribe((data) => {
        const dbUser = new User(data);
        setUser(dbUser);
      }),
    ];

    return () => s.forEach((i) => i.unsubscribe());
  }, []);

  return { user, setUser };
}

export function useUserLoginLogoutEffect() {
  const { setUser: setUserData } = useUserDataEffect();
  const [user, setUser] = useState<User | undefined>(undefined);
  useEffect(() => {
    const s = [
      authenticationService.listenForUserLogout().subscribe(() => {
        setUser(undefined);
        setUserData(undefined);
      }),
      userDataListener.subscribe((u) => {
        const _user = new User(u);
        setUser(_user);
        setUserData(_user);
      }),
    ];

    return () => s.forEach((i) => i.unsubscribe());
  }, []);

  return { user, setUser };
}

export function useUrlEffect(setUrl: StateUpdater<string>) {
  useEffect(() => {
    const s = urlLocationListener.subscribe((u) => {
      setUrl(u);
    });

    return () => s.unsubscribe();
  }, [setUrl]);
}

export function useSetFormikUser(formikContext: any, id: string) {
  useEffect(() => {
    const s = userDataListener.subscribe((u) => {
      formikContext.setValues(new User(u));
    });

    return () => s.unsubscribe();
  }, [id]);
}
