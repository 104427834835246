import { h } from 'preact';
import { route } from 'preact-router';
import { Link } from 'preact-router/match';
import { googleProvider } from '../../firebase';
import { useUserLoginLogoutEffect } from '../../helpers/hooks';
import { Menu, MenuItem } from '../../helpers/menu';
import authenticationService from '../../services/authentication-service';
import styles from './header.module.scss';

export default function Header(props: any) {
  const { user } = useUserLoginLogoutEffect();

  function handleLogin() {
    authenticationService.login(googleProvider);
  }

  function handleLogout() {
    authenticationService.logout();
  }

  function handleProfile(e: Event) {
    if (!user) {
      return;
    }
    e.preventDefault();
    route(`/profile/${user?.id}`);
  }

  return (
    <header class={styles.header_root}>
      <section>
        {user ? (
          <nav class="nav nav-pills">
            <Link href="/home" class="nav-link" activeClassName="active">
              Home
            </Link>
            <Link href="/games" class="nav-link" activeClassName="active">
              Games
            </Link>
          </nav>
        ) : (
          <nav>
            <a />
            <a />
          </nav>
        )}

        <aside>
          <Menu
            id="user-dropdown"
            trigger={
              <button className="btn btn-dark dropdown-toggle">
                {user ? user.name : 'Account'}
              </button>
            }
          >
            {user && (
              <MenuItem>
                <Link
                  className="d-flex"
                  key="profile"
                  href={`/profile/${user?.id}`}
                  onClick={handleProfile}
                >
                  Profile
                </Link>
              </MenuItem>
            )}
            <MenuItem onClick={user ? handleLogout : handleLogin}>
              {user ? 'Log Out' : 'Log In'}
            </MenuItem>
          </Menu>
        </aside>
      </section>
    </header>
  );
}
