import { BaseRest } from './base';
import storageService from '../../app/services/storage-service';
import userService from '../../app/services/user-service';
import { userDataListener } from '../../app/game-services/listeners-service';

interface IUser extends BaseRest {
  id: string;
  name: string;
  username: string;
  initialized: boolean;
  profilePicturePath: string;
}

export class User extends BaseRest implements IUser {
  id: string;
  name: string;
  username: string;
  initialized: boolean;
  profilePicturePath: string;

  constructor(u: IUser) {
    super(u);

    this.id = u.id;
    this.name = u.name;
    this.username = u.username;
    this.initialized = u.initialized;
    this.profilePicturePath = u.profilePicturePath ?? '';
  }

  getProfilePicture() {
    return storageService.get(this.profilePicturePath);
  }

  async setProfilePicture(data: Blob) {
    const snapshot = await storageService.put(`profile/${this.id}`, data);
    await userService.patch(this.id, {
      profilePicturePath: snapshot.metadata.fullPath,
    } as User);

    const dbUser = (await userService.get(this.id)).data() as User;
    userDataListener.next(dbUser);
  }

  deleteProfilePicture() {
    return storageService.delete(this.profilePicturePath);
  }

  static getInitialForm() {
    return {
      ...super.getInitialForm(),
      id: '',
      name: '',
      username: '',
      initialized: false,
      profilePicturePath: '',
    } as User;
  }
}
