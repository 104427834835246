import { DragControls, Reorder, useDragControls } from 'framer-motion';
import { h, VNode } from 'preact';
import Icon from './icon';

interface DragHandleProps {
  size: number;
  dragControls?: DragControls;
  className?: string;
}
export function DragHandle({ size, dragControls, className }: DragHandleProps) {
  if (!dragControls) {
    return (
      <Icon
        class={`drag-handle ${className}`}
        icon="grip-vertical"
        size={size}
      />
    );
  }

  return (
    <div
      className={`drag-handle ${className}`}
      onPointerDown={(e) => {
        dragControls?.start(e);
      }}
    >
      <Icon icon="grip-vertical" size={size} />
    </div>
  );
}

interface DragRowProps {
  value: any;
  id: string | number;
  children: VNode | VNode[];
  className?: string;
  disabled?: boolean;
}
export function DragRow({
  value,
  id,
  children,
  className,
  disabled,
}: DragRowProps) {
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      value={value}
      id={id}
      dragListener={false}
      dragControls={disabled ? undefined : dragControls}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      className={className}
    >
      <DragHandle dragControls={dragControls} size={24} />
      {children}
    </Reorder.Item>
  );
}
