import { Timestamp } from 'firebase/firestore';

export enum BoardActionType {
  Cursor = 'cursor',
  Arrow = 'arrow',
  Signal = 'signal',
  None = 'none',
}
type Actions =
  | {
      type: BoardActionType.Cursor;
      x: number;
      y: number;
    }
  | {
      type: BoardActionType.Arrow;
      startX: number;
      startY: number;
      endX: number;
      endY: number;
    }
  | {
      type: BoardActionType.Signal;
      x: number;
      y: number;
    }
  | {
      type: BoardActionType.None;
    };

interface IBoardAction {
  userId: string;
  action: Actions;
  updated: Timestamp;
}

export class BoardAction implements IBoardAction {
  userId: string;
  action: Actions;
  updated: Timestamp;

  constructor(ba: IBoardAction) {
    this.userId = ba.userId;
    this.action = ba.action;
    this.updated = ba.updated;
  }
}
