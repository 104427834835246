import { Timestamp } from 'firebase/firestore';

interface IOptions {
  created: Timestamp;
  updated: Timestamp;
}

export class BaseRest {
  created: Timestamp;
  updated: Timestamp;

  constructor({ created, updated }: IOptions) {
    this.created = created;
    this.updated = updated;
  }

  static getInitialForm() {
    return {
      created: Timestamp.now(),
      updated: Timestamp.now(),
    } as IOptions;
  }
}
