import { Modal } from 'bootstrap';
import { h } from 'preact';
import { StateUpdater, useRef, useState } from 'preact/hooks';
import { Fragment } from 'react';
import Dialog from '../../../../../helpers/dialog';
import Icon from '../../../../../helpers/icon';
import { evaluate } from './evaluate';
import { DiceRollResult } from './input-types';
import styles from './inputs.module.scss';

interface RollButtonProps {
  rollDice?: (rollResult: DiceRollResult) => void;
  showRollModal: boolean;
  value: string;
  displayKey: string;
  setIsValidExpression: StateUpdater<boolean>;
  setExpressionTotal: StateUpdater<number | undefined>;
}
export function RollButton({
  rollDice,
  showRollModal,
  value,
  displayKey,
  setExpressionTotal,
  setIsValidExpression,
}: RollButtonProps) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  function roll() {
    if (showRollModal) {
      setOpen(true);
      return;
    }

    run();
  }

  function rollWithInput(e: Event) {
    e.preventDefault();
    if (!ref.current) {
      return;
    }
    try {
      run(ref.current.value);
      const modal = Modal.getInstance('dice-roll');
      modal?.hide();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  }

  function run(diceRoll = '') {
    const diceRollString = diceRoll ? `${diceRoll} + (${value})` : value;

    const { isValid, rollResult } = evaluate(
      displayKey,
      diceRollString
    );
    if (rollResult) {
      setIsValidExpression(isValid);
      setExpressionTotal(rollResult.total);
      rollDice?.(rollResult);
    }
  }

  return (
    <Fragment>
      <button onClick={roll} className={`${styles.roll} btn btn-primary`}>
        <Icon size={18} icon="dice-6" />
        &nbsp; Roll
      </button>

      <Dialog
        id="dice-roll"
        setShow={setOpen}
        show={open}
        title="Roll Dice"
        size="modal-sm"
        children={
          <form onSubmit={rollWithInput} className="modal-body">
            <label className="form-label" for="dice-roll">
              Type a dice roll like 1d6...
            </label>
            <div className="input-group">
              <input
                autofocus
                className="form-control focus"
                placeholder="Type a dice roll like 1d6..."
                id="dice-roll"
                ref={ref}
              />
              <button type="submit" className="btn btn-primary">
                <Icon size={18} icon="dice-6" />
              </button>
            </div>
          </form>
        }
      />
    </Fragment>
  );
}
