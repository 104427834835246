import { Viewport } from 'pixi-viewport';
import {
  Application as PixiApplication,
  Container,
  DisplayObject,
  InteractionEvent,
} from 'pixi.js';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Board } from '../../types/database/board';
import {
  BoardAction,
  BoardActionType,
} from '../../types/database/board-action';
import { BoardAsset } from '../../types/database/board-asset';
import { Game } from '../../types/database/game';
import { User } from '../../types/database/user';
import { CustomMap } from '../../types/game-structures';
import { ToastConfig } from '../components/toast/toast-list';
import { FirebaseUser } from '../firebase-user';

const BUFFER_SIZE = 1;

export interface Application {
  app: PixiApplication;
  appId: string;
  viewport: Viewport;
  height: number;
  width: number;
}

export interface BatchSpriteOpacity {
  container: DisplayObject;
  isOwner: boolean;
  hasAccess: boolean;
  userId: string;
  id: string;
  data: BoardAsset;
}

export const SIDE_BAR_STATE_KEY = 'SIDE_BAR_STATE';
export const SHARE_BOARD_ACTION_KEY = 'SHARE_BOARD_ACTION';
export const BOARD_ACTION_TOOL_KEY = 'BOARD_ACTION_TOOL';
const scaleListener = new BehaviorSubject<number>(1);
const applicationListener = new ReplaySubject<Application>(BUFFER_SIZE);
const applicationRenderStateListener = new BehaviorSubject<
  'loading' | 'finished'
>('loading');
const authenticationStateListener = new ReplaySubject<FirebaseUser | null>(
  BUFFER_SIZE
);
const userDataListener = new ReplaySubject<User>(BUFFER_SIZE);
const urlLocationListener = new ReplaySubject<string>(BUFFER_SIZE);
const toastsListener = new ReplaySubject<ToastConfig[]>(BUFFER_SIZE);
const isCallingWebService = new BehaviorSubject<boolean>(false);
const sideBarListener = new BehaviorSubject<'open' | 'closed'>(
  (localStorage.getItem(SIDE_BAR_STATE_KEY) as any) || 'open'
);
const characterMoveListener = new ReplaySubject<{
  x: number;
  y: number;
  id: string;
}>(BUFFER_SIZE);
const backgroundSizeListener = new ReplaySubject<{
  height: number;
  width: number;
  id: string;
}>();
const gameListener = new BehaviorSubject<[string, Game | undefined]>([
  '',
  undefined,
]);
const boardListener = new ReplaySubject<Board>(BUFFER_SIZE);

export enum CharacterClickActionType {
  Bind = 'bind',
  CharacterSettings = 'characterSettings',
  BackgroundSettings = 'backgroundSettings',
}
export type CharacterClickAction =
  | {
      type: CharacterClickActionType.Bind;
      character: Container;
    }
  | {
      type: CharacterClickActionType.CharacterSettings;
      character: Container;
    }
  | {
      type: CharacterClickActionType.BackgroundSettings;
      background: Container;
      event: InteractionEvent;
    };
const characterClickListener = new Subject<CharacterClickAction>();
const usersInGameListener = new BehaviorSubject<CustomMap<User> | null>(null);
const boardActionListener = new ReplaySubject<BoardAction['action']>(
  BUFFER_SIZE
);
const shareBoardActionListener = new BehaviorSubject(
  !!localStorage.getItem(SHARE_BOARD_ACTION_KEY)
);
const boardActionToolListener = new BehaviorSubject<BoardActionType>(
  (localStorage.getItem(BOARD_ACTION_TOOL_KEY) as BoardActionType) ||
    BoardActionType.None
);
const characterListListener = new BehaviorSubject<Array<BatchSpriteOpacity>>(
  []
);

shareBoardActionListener.subscribe((share) => {
  if (share) {
    localStorage.setItem(SHARE_BOARD_ACTION_KEY, 'true');
  } else {
    localStorage.removeItem(SHARE_BOARD_ACTION_KEY);
  }
});

boardActionToolListener.subscribe((actionType) => {
  localStorage.setItem(BOARD_ACTION_TOOL_KEY, actionType);
});

export {
  scaleListener,
  applicationListener,
  applicationRenderStateListener,
  authenticationStateListener,
  userDataListener,
  urlLocationListener,
  toastsListener,
  sideBarListener,
  characterMoveListener,
  backgroundSizeListener,
  characterClickListener,
  isCallingWebService,
  usersInGameListener,
  gameListener,
  boardListener,
  boardActionListener,
  shareBoardActionListener,
  boardActionToolListener,
  characterListListener,
};
