import { useFormik } from 'formik';
import { h } from 'preact';
import { useUniqueKeys } from '../../grid-sort';
import * as yup from 'yup';
import { isFieldValid } from '../../../../../helpers/errors';
import { uniqueKey } from '../../../../../helpers/validators';
import { formikPropsSubmitOnInput } from '../../../../../helpers/form-helpers';

interface UniqueKeyFormProps {
  value: string;
  onChange: (value: string) => void;
}
export function UniqueKeyForm({ value, onChange }: UniqueKeyFormProps) {
  const keys = useUniqueKeys();

  const { submitForm, getFieldProps, validateForm, touched, errors } =
    useFormik({
      validateOnBlur: false,
      initialValues: {
        key: value,
      },
      validationSchema: yup.object({
        key: yup.string().required('Required'),
      }),
      validate: (v) => {
        let err = {};

        err = Object.assign(err, uniqueKey(keys, v.key, value));

        return err;
      },
      onSubmit: (v) => {
        onChange(v.key);
      },
    });

  return (
    <input
      type="text"
      className={`form-control ${isFieldValid({ touched, errors }, 'key')}`}
      spellCheck
      autoComplete="off"
      title={errors.key}
      {...formikPropsSubmitOnInput({
        submitForm,
        getFieldProps,
        validateForm,
      })('key')}
    />
  );
}
