import Compressor from 'compressorjs';

export function compress(
  data: Blob,
  options: Omit<Compressor.Options, 'success' | 'error'> = {}
) {
  return new Promise<Blob>((resolve, reject) => {
    const defaults = {
      strict: true,
      checkOrientation: true,
      maxHeight: 200,
      maxWidth: 200,
    };
    new Compressor(data, {
      ...Object.assign(defaults, options),
      success: (file) => {
        resolve(file);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}
