import { onSnapshot, Timestamp } from 'firebase/firestore';
import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { CharacterSheet } from '../../../../types/database/character-sheet';
import { useUserLoginLogoutEffect } from '../../../helpers/hooks';
import characterSheetService from '../../../services/character-sheet-service';
import SheetVersionService from '../../../services/sheet-version-service';

export function useCharacterSheet(id?: string) {
  const [characterSheet, setCharacterSheet] = useState<
    CharacterSheet | undefined
  >(undefined);
  const { user } = useUserLoginLogoutEffect();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!id) {
      characterSheetService
        .add({
          name: 'Untitled New Sheet',
          owner: user.id,
          contributors: [],
          public: false,
          tags: [],
          created: Timestamp.now(),
          updated: Timestamp.now(),
        })
        .then((sheet) => {
          const sheetVersionService = new SheetVersionService(sheet.id);

          sheetVersionService
            .add({
              patchNotes: '',
              sheet: {
                pages: {
                  header: {
                    id: 'header',
                    index: 0,
                    page: {
                      type: 'header',
                    },
                    layouts: {},
                  },
                  settings: {
                    id: 'settings',
                    index: 1,
                    page: {
                      type: 'settings',
                      values: {},
                    },
                    layouts: {},
                  },
                },
              },
              created: Timestamp.now(),
              updated: Timestamp.now(),
            })
            .then((version) => {
              route(
                `/character-sheets/sheet-editor/${sheet.id}/${version.id}`,
                true
              );
            });
        });
    } else {
      onSnapshot(characterSheetService.getDoc(id), (snapshot) => {
        if (snapshot.exists()) {
          setCharacterSheet(
            new CharacterSheet(snapshot.data() as CharacterSheet)
          );
        } else {
          route(`/character-sheets`);
        }
      });
    }
  }, [id, user]);

  return {
    characterSheet,
  };
}
