import { FieldInputProps, FormikErrors } from 'formik';

interface PropTypes {
  submitForm: () => Promise<any>;
  getFieldProps: (name: string) => FieldInputProps<any>;
  validateForm: (values?: any) => Promise<FormikErrors<any>>;
}

export function formikPropsSubmitOnInput({
  submitForm,
  validateForm,
  getFieldProps,
}: PropTypes) {
  function saveOnInput(e: Event, key: string) {
    getFieldProps(key).onChange(e);

    validateForm().then((err) => {
      submitForm().then();
    });
  }

  return (name: string) => {
    return {
      ...getFieldProps(name),
      onInput: (e: Event) => saveOnInput(e, name),
    };
  };
}

export function formikValidateOnInput(
  getFieldProps: (name: string) => FieldInputProps<any>
) {
  return (name: string) => {
    const props = getFieldProps(name);
    return {
      ...props,
      onInput: props.onChange,
    };
  };
}
