import { Timestamp } from '@firebase/firestore';
import { motion } from 'framer-motion';
import { marked } from 'marked';
import { h } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';
import { Template } from '../../../../../../types/database/sheet-version';
import { useUserDataEffect } from '../../../../../helpers/hooks';
import { useObservable } from '../../../../../helpers/observable-hook';
import ChatService from '../../../../../services/chat-service';
import { useSheetContext } from '../../../../game-grid/sheet/sheet-context';
import {
  dispatchOverlayDataActionListener,
  overlayDataListener,
} from '../../../../game-grid/sheet/use-overlay-data';
import { dispatchSheetActionListener } from '../../grid-sort';
import { CalculatedInput } from '../../sidebar/inputs/calculated';
import { DiceRollResult } from '../../sidebar/inputs/input-types';
import { BooleanPreview } from './boolean';
import styles from './displays.module.scss';
import { NumberPreview } from './number';
import { NumberSelectPreview } from './number-select';
import { DisplayRendererProps } from './preview-types';
import { StringPreview } from './string';
import { StringSelectPreview } from './string-select';

export function DisplayRenderer({
  page,
  layout,
  display,
}: DisplayRendererProps) {
  const sheetContext = useSheetContext();
  const { user } = useUserDataEffect();
  const chatService = useMemo(
    () =>
      sheetContext?.gameId ? new ChatService(sheetContext?.gameId) : undefined,
    [sheetContext?.gameId]
  );
  const overlayData = useObservable(overlayDataListener);
  const onChange = useCallback(
    (config: Template.FieldValue) => {
      if (overlayData) {
        dispatchOverlayDataActionListener.next({
          type: 'set-single',
          key: display.id,
          value: config,
        });
        return;
      }

      display.config.value = config.value;

      dispatchSheetActionListener.next({
        type: 'update-display',
        display,
        layout,
        page,
      });
    },
    [display, layout, page, overlayData]
  );
  const rollDice = useCallback(
    (rollResult: DiceRollResult) => {
      const split = rollResult.output.split(':');
      const equation = split[1];

      if (!equation || !user?.id || !chatService) {
        return;
      }

      const value = {
        content: marked(`${display.label.text} \n > ${equation.trim()} \n\n`),
        userId: user.id,
        created: Timestamp.now(),
        updated: Timestamp.now(),
      };

      chatService.add(value);
    },
    [chatService, display.label.text, user?.id]
  );

  const data = overlayData?.[display.id];
  const config = useMemo(() => {
    if (data?.type === 'single') {
      return data.value;
    }

    return display.config;
  }, [display.config, data]);

  function getDisplay() {
    switch (display.config.type) {
      case 'number':
        return (
          <NumberPreview
            displayKey={display.key}
            config={config}
            label={display.label}
            onChange={onChange}
          />
        );
      case 'string':
        return (
          <StringPreview
            displayKey={display.key}
            config={config}
            label={display.label}
            onChange={onChange}
          />
        );
      case 'select-string':
        return (
          <StringSelectPreview
            displayKey={display.key}
            config={config}
            label={display.label}
            onChange={onChange}
            options={display.config.options}
          />
        );
      case 'select-number':
        return (
          <NumberSelectPreview
            displayKey={display.key}
            config={config}
            label={display.label}
            onChange={onChange}
            options={display.config.options}
          />
        );
      case 'boolean':
        return (
          <BooleanPreview
            displayKey={display.key}
            config={config}
            label={display.label}
            onChange={onChange}
          />
        );
      case 'calculated':
        return (
          <CalculatedInput
            displayKey={display.key}
            config={config}
            label={display.label}
            action={display.action}
            onChange={onChange}
            rollDice={overlayData && rollDice}
            showRollModal={true}
          />
        );
    }
  }

  return (
    <motion.div
      layout
      initial={{ opacity: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className={styles.display}
      style={{
        gridColumn: `span ${display.column.spanH}`,
        gridRow: `span ${display.column.spanV}`,
      }}
    >
      {getDisplay()}
    </motion.div>
  );
}
