import { Collapse } from 'bootstrap';
import { cloneElement, h, VNode } from 'preact';
import { useCallback, useRef, useState } from 'preact/hooks';
import { Fragment } from 'react';

interface PropTypes {
  trigger: VNode;
  children: VNode;
}
export default function CollapseContent({ trigger, children }: PropTypes) {
  const [collapse, setCollapse] = useState<Collapse | undefined>(undefined);
  const triggerRef = useRef<HTMLElement>(null);
  const ref = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }

    setCollapse(
      new Collapse(node, {
        toggle: false,
      })
    );
  }, []);

  function onKeyDown(event: KeyboardEvent) {
    switch (event.code) {
      case 'Space':
      case 'Enter':
        onClick();
    }
  }

  function onClick() {
    triggerRef.current?.classList.toggle('open');
    collapse?.toggle();
  }

  return (
    <Fragment>
      {cloneElement(trigger, {
        onclick: onClick,
        onkeydown: onKeyDown,
        ref: triggerRef,
        tabIndex: 0,
      })}
      <div className="collapse" ref={ref}>
        {children}
      </div>
    </Fragment>
  );
}
