import { DiceRoll } from '@dice-roller/rpg-dice-roller';
import {
  overlayRegisteredDisplaysListener,
  overlaySingleKeysListener,
  overlayUserListKeysListener,
} from '../../../../game-grid/sheet/use-overlay-data';
import {
  registeredDisplaysListener,
  settingsKeysListener,
  userListKeysListener,
} from '../../grid-sort';
import { displayKeysListener } from '../../listeners';
import { DiceRollResult } from './input-types';

const breakIncludeRegex = new RegExp(
  '(\\d+d\\d+)|(\\$[a-zA-Z0-9]+)|([0-9])+|([\\-+%/*()])',
  'g'
);
const diceRollRegex = new RegExp('(\\dd\\d)', 'g');

interface ValidExpression {
  isValid: boolean;
  rollResult?: DiceRollResult;
}

export function evaluate(displayKey: string, value: string): ValidExpression {
  const displayKeys = displayKeysListener.value;
  const registeredDisplays = registeredDisplaysListener.value;
  const overlayRegisteredDisplays = overlayRegisteredDisplaysListener.value;

  try {
    const initialId = displayKeys[displayKey]?.id ?? '';
    const registeredDisplayMap = registeredDisplays.get(initialId);
    const overlayRegisteredDisplayMap =
      overlayRegisteredDisplays.get(initialId);

    let accumulator = 0;
    if (registeredDisplayMap) {
      registeredDisplayMap.forEach((displayValue) => {
        accumulator += displayValue;
      });
    }

    if (overlayRegisteredDisplayMap) {
      overlayRegisteredDisplayMap.forEach((overlayValue) => {
        accumulator += overlayValue;
      });
    }

    const exp = value.trim();

    const split = Array.from(
      exp.matchAll(breakIncludeRegex),
      (x) => x[0] ?? ''
    );

    if (split.includes(`$${displayKey}`)) {
      throw new Error('Equation cannot reference self');
    }

    const result = buildExpression(split, initialId, accumulator).join('');
    const roll = new DiceRoll(result);
    const rollResult = roll.toJSON();

    rollResult.total = Math.floor(rollResult.total);

    return {
      isValid: true,
      rollResult,
    };
  } catch (error) {
    // console.log(error);
    return {
      isValid: false,
    };
  }
}

function buildExpression(
  exp: string[],
  displayId: string,
  accumulator: number
): string[] {
  const displayKeys = displayKeysListener.value;
  const settingsKeys = settingsKeysListener.value;
  const userListKeys = userListKeysListener.value;
  const overlayUserListKeys = overlayUserListKeysListener.value;
  const overlaySingleKeys = overlaySingleKeysListener.value;
  const builtExp: (string[] | string)[] = exp;

  for (let index = 0; index < exp.length; index++) {
    const value = exp[index]?.trim();
    const modifiedValue = value?.replace('$', '');

    if (!modifiedValue || !value) {
      builtExp[index] = '';
      continue;
    }

    const d = displayKeys[modifiedValue];
    const s = settingsKeys[modifiedValue];
    const o = overlaySingleKeys[modifiedValue];
    const u = userListKeys[modifiedValue];
    const ou = overlayUserListKeys[modifiedValue];
    const listValue = ou ?? u;
    const isDiceRoll = diceRollRegex.test(modifiedValue);
    const isRest = modifiedValue === 'rest';

    if (d) {
      if (d.config.type === 'calculated') {
        const value = o?.value.toString() ?? d.config.value;
        const split = Array.from(
          value.matchAll(breakIncludeRegex),
          (x) => x[0] ?? ''
        );
        const innerExp = buildExpression(
          ['(', ...split, ')'],
          displayId,
          accumulator
        );
        builtExp[index] = innerExp;
      } else if (d.config.type === 'boolean') {
        const value = o?.value ?? d.config.value;
        builtExp[index] = (+value).toString();
      } else {
        const value = o?.value ?? d.config.value;
        builtExp[index] = value.toString();
      }
    }
    if (listValue) {
      if (listValue.value.type === 'calculated') {
        const split = Array.from(
          listValue.value.value.matchAll(breakIncludeRegex),
          (x) => x[0] ?? ''
        );
        const innerExp = buildExpression(
          ['(', ...split, ')'],
          displayId,
          accumulator
        );
        builtExp[index] = innerExp;
      } else if (listValue.value.type === 'boolean') {
        builtExp[index] = (+listValue.value.value).toString();
      } else {
        builtExp[index] = listValue.value.value.toString();
      }
    }
    if (s && s.config.value) {
      builtExp[index] = s.config.value.toString();
    }
    if (isDiceRoll) {
      builtExp[index] = modifiedValue;
    }
    if (isRest) {
      builtExp[index] = accumulator.toString();
    }
  }

  return builtExp.flat();
}
