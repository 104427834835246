import { computePosition, flip, getScrollParents } from '@floating-ui/dom';
import {
  AnimatePresence,
  LayoutGroup,
  motion,
  useMotionValue,
} from 'framer-motion';
import { h, RefObject } from 'preact';
import { createPortal } from 'preact/compat';
import { Ref, useCallback, useEffect } from 'preact/hooks';
import styles from './inputs.module.scss';

const portal = document.getElementById('autocompletes') as HTMLDivElement;

export interface Caret {
  top: number;
  left: number;
  height: number;
}

interface AutocompleteDropdownProps {
  open: boolean;
  onAutocompleteKeydown: (e: KeyboardEvent) => void;
  autocompleteClick: (index: number) => () => void;
  autocompleteRef: RefObject<HTMLUListElement>;
  selected: number;
  options: string[];
  caret: Caret;
  triggerRef: Ref<HTMLTextAreaElement>;
}
export function AutocompleteDropdown({
  open,
  onAutocompleteKeydown,
  autocompleteClick,
  autocompleteRef,
  selected,
  options,
  caret,
  triggerRef,
}: AutocompleteDropdownProps) {
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const updatePosition = useCallback(
    (caret: Caret) => {
      return () => {
        if (!triggerRef.current || !autocompleteRef.current) {
          return;
        }

        const textareaRect = triggerRef.current.getBoundingClientRect();
        const autocompleteRect =
          autocompleteRef.current.getBoundingClientRect();

        computePosition(triggerRef.current, autocompleteRef.current, {
          placement: 'bottom-end',
          middleware: [
            flip({
              fallbackPlacements: ['top-end'],
            }),
          ],
        }).then((pos) => {
          console.log(caret);
          switch (pos.placement) {
            case 'bottom-end':
              x.set(
                pos.x + caret.left - textareaRect.width + autocompleteRect.width
              );
              y.set(pos.y + caret.top + caret.height - textareaRect.height);
              break;
            case 'top-end':
              x.set(
                pos.x + caret.left - textareaRect.width + autocompleteRect.width
              );
              y.set(pos.y + caret.top - caret.height);
              break;
          }
        });
      };
    },
    [caret]
  );

  useEffect(() => {
    if (!triggerRef.current) {
      return;
    }
    const trigger = triggerRef.current;

    updatePosition(caret)();

    [...getScrollParents(trigger)].forEach((el) => {
      el.addEventListener('scroll', updatePosition(caret), {
        passive: true,
      });
      el.addEventListener('resize', updatePosition(caret), {
        passive: true,
      });
    });

    return () => {
      [...getScrollParents(trigger)].forEach((el) => {
        el.removeEventListener('scroll', updatePosition(caret));
        el.removeEventListener('resize', updatePosition(caret));
      });
    };
  }, [caret]);

  return createPortal(
    <AnimatePresence>
      {open && (
        <motion.ul
          layoutScroll
          onKeyDown={onAutocompleteKeydown}
          tabindex="0"
          ref={autocompleteRef}
          initial={{
            opacity: 0,
            scaleY: 0.9,
            pointerEvents: 'none',
          }}
          animate={{
            opacity: 1,
            scaleY: 1,
            pointerEvents: 'all',
          }}
          exit={{
            opacity: 0,
            scaleY: 0.9,
            pointerEvents: 'none',
          }}
          className={`${styles.autocomplete} list-group`}
          style={{
            originX: 0,
            originY: 0,
            x,
            y,
          }}
        >
          <LayoutGroup>
            {options.map((value, index) => {
              return (
                <motion.li
                  layout
                  onClick={autocompleteClick(index)}
                  key={value}
                  className={`list-group-item ${
                    selected === index && 'active'
                  }`}
                  aria-current={selected === index}
                >
                  {value}
                </motion.li>
              );
            })}
          </LayoutGroup>
        </motion.ul>
      )}
    </AnimatePresence>,
    portal
  );
}
