import { h } from 'preact';
import { PreviewDisplayProps } from './preview-types';

interface StringSelectProps {
  options: string[];
}

export function StringSelectPreview({
  config,
  options,
  label,
  onChange,
}: PreviewDisplayProps & StringSelectProps) {
  if (config.type !== 'select-string') {
    return null;
  }

  if (!options) {
    options = [];
  }

  function _onChange(e: Event) {
    const target = e.target as HTMLSelectElement;

    config.value = target.value;

    onChange(config);
  }

  return (
    <div className="form-floating">
      <select className="form-select" value={config.value} onChange={_onChange}>
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <label>{label.text}</label>
    </div>
  );
}
