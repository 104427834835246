import { motion } from 'framer-motion';
import { h } from 'preact';
import { useRef } from 'preact/hooks';
import CollapseContent from '../../../../../helpers/collapse';
import Icon from '../../../../../helpers/icon';
import { onFocus } from '../../../../../helpers/input-helpers';
import styles from './displays.module.scss';
import { UniqueKeyForm } from './unique-key-input';
import { UserListDisplayProps } from './user-list-types';

export function NumberUserField({
  field,
  label,
  onChange,
}: UserListDisplayProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  if (field.value.type !== 'number') {
    return null;
  }

  function onInput() {
    const target = inputRef.current;

    if (!target || +target.value === field.value.value) {
      return;
    }

    field.value.value = +target.value;

    onChange(field.value);
  }

  function _onChange(newKey: string) {
    field.key = newKey;

    onChange(field.value, field.key);
  }

  return (
    <div className={styles.userListItemContainer}>
      <CollapseContent
        trigger={
          <label className="form-label">
            <Icon icon="caret-down" size={18} /> Field Key: {field.key}
          </label>
        }
      >
        <UniqueKeyForm value={field.key} onChange={_onChange} />
      </CollapseContent>
      <motion.div layout className="form-floating">
        <input
          className="form-control"
          placeholder={label.text}
          id="number"
          type="number"
          ref={inputRef}
          onBlur={onInput}
          onFocus={onFocus}
          value={field.value.value}
        />
        <label for="number">{label.text}</label>
      </motion.div>
    </div>
  );
}
