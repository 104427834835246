import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../firebase';

export class RestService<T> {
  private documentPath: string;

  constructor(path: string) {
    this.documentPath = path;
  }

  base() {
    return doc(db, this.documentPath);
  }

  get(id: string) {
    return getDoc(this.getDoc(id));
  }

  getDoc(id: string) {
    return doc(db, `${this.documentPath}/${id}`);
  }

  find() {
    return collection(db, `${this.documentPath}`);
  }

  add(data: Partial<T>) {
    const ref = collection(db, `${this.documentPath}`);
    return addDoc<DocumentData>(ref, { ...data });
  }

  set(id: string, data: T) {
    return setDoc(this.getDoc(id), { ...data });
  }

  patch(id: string, data: Partial<T>) {
    const updated = Timestamp.now();

    return updateDoc<DocumentData>(this.getDoc(id), { ...data, updated });
  }

  delete(id: string) {
    return deleteDoc(this.getDoc(id));
  }
}
