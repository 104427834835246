import { onSnapshot, Timestamp } from 'firebase/firestore';
import { useEffect, useMemo, useReducer } from 'preact/hooks';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Template } from '../../../../types/database/sheet-version';
import { UserCharacter } from '../../../../types/database/user-character';
import { CustomMap } from '../../../../types/game-structures';
import UserCharacterService from '../../../services/user-character-service';
import { displayKeysListener } from '../../character-sheets/editor/listeners';

export const overlayDataListener = new ReplaySubject<Template.UserOverlay>();
export const dispatchOverlayDataActionListener =
  new ReplaySubject<OverlayDataAction>();
export const overlaySingleKeysListener = new BehaviorSubject<
  CustomMap<Template.FieldValue>
>({});
export const overlayUserListKeysListener = new BehaviorSubject<
  CustomMap<Template.UserListField>
>({});
export const overlayRegisteredDisplaysListener = new BehaviorSubject<
  Map<string, Map<string, number>>
>(new Map());

const patchOverlayDataListener = new ReplaySubject<Template.UserOverlay>();

type OverlayDataAction =
  | { type: 'set-overlay-data'; overlayData: Template.UserOverlay }
  | { type: 'set-single'; key: string; value: Template.FieldValue }
  | { type: 'remove-single'; key: string }
  | {
      type: 'set-user-list';
      layout: string;
      value: CustomMap<Template.UserList>;
    }
  | {
      type: 'set-user-list-row';
      layout: string;
      userList: Template.UserList;
    }
  | {
      type: 'remove-user-list-row';
      layout: string;
      userListItem: Template.UserList;
    }
  | {
      type: 'set-user-list-field';
      layout: string;
      userListKey: string;
      field: Template.UserListField;
    };

function overlayDataReducer(
  overlayData: Template.UserOverlay,
  action: OverlayDataAction
) {
  switch (action.type) {
    case 'set-overlay-data': {
      updateUserListKeys(action.overlayData);
      return { ...action.overlayData };
    }
    case 'set-single': {
      overlayData[action.key] = {
        type: 'single',
        value: {
          type: action.value.type,
          value: action.value.value,
        } as Template.FieldValue,
      };
      break;
    }
    case 'remove-single': {
      delete overlayData[action.key];
      break;
    }
    case 'set-user-list': {
      overlayData[action.layout] = {
        type: 'user-list',
        values: action.value,
      };

      updateUserListKeys(overlayData);
      break;
    }
    case 'set-user-list-row': {
      const list = overlayData[action.layout];

      if (list && list.type === 'user-list') {
        list.values[action.userList.id] = action.userList;
      }

      updateUserListKeys(overlayData);
      break;
    }
    case 'remove-user-list-row': {
      
      const layout = overlayData[action.layout];
      
      if (layout && layout.type === 'user-list') {
        console.dir({action, layout });

        delete layout.values[action.userListItem.id];
      }
      break;
    }
    case 'set-user-list-field': {
      const list = overlayData[action.layout];

      if (list && list.type === 'user-list') {
        const field = list.values[action.userListKey];

        if (field) {
          field.values[action.field.id] = action.field;
        }
      }

      updateUserListKeys(overlayData);
      break;
    }
  }
  patchOverlayDataListener.next(overlayData);
  return overlayData;
}

function updateUserListKeys(overlayData: Template.UserOverlay) {
  const newOverlayUserListKeys: CustomMap<Template.UserListField> = {};
  const newOverlaySingleKeys: CustomMap<Template.FieldValue> = {};
  const newOverlayRegisteredDisplays: Map<
    string,
    Map<string, number>
  > = new Map();
  const displayKeys = displayKeysListener.value;

  Object.entries(overlayData).forEach(([key, overlay]) => {
    if (overlay.type === 'user-list') {
      Object.values(overlay.values).forEach((row) => {
        Object.values(row.values).forEach((item) => {
          const linkedDisplay = displayKeys[item.displayId];
          newOverlayUserListKeys[item.key] = item;

          if (
            linkedDisplay &&
            'registeredDisplayId' in linkedDisplay.config &&
            linkedDisplay.config.registeredDisplayId &&
            row.enabled
          ) {
            switch (item.value.type) {
              case 'number':
              case 'select-number':
              case 'boolean':
              case 'calculated': {
                if (
                  newOverlayRegisteredDisplays.has(
                    linkedDisplay.config.registeredDisplayId
                  )
                ) {
                  newOverlayRegisteredDisplays
                    .get(linkedDisplay.config.registeredDisplayId)
                    ?.set(item.id, +item.value.value);
                } else {
                  const newMap = new Map();
                  newMap.set(item.id, +item.value.value);
                  newOverlayRegisteredDisplays.set(
                    linkedDisplay.config.registeredDisplayId,
                    newMap
                  );
                }
              }
            }
          }
        });
      });
    }
    if (overlay.type === 'single') {
      const linkedDisplay = displayKeys[key];

      if (linkedDisplay) {
        newOverlaySingleKeys[key] = overlay.value;
        newOverlaySingleKeys[linkedDisplay.key] = overlay.value;
      }
    }
  });

  overlaySingleKeysListener.next(newOverlaySingleKeys);
  overlayUserListKeysListener.next(newOverlayUserListKeys);
  overlayRegisteredDisplaysListener.next(newOverlayRegisteredDisplays);
}

export function useOverlayData(gameId: string, characterId: string) {
  const userCharacterService = useMemo(
    () => new UserCharacterService(gameId),
    [gameId]
  );
  const [overlayData, dispatchOverlayData] = useReducer(overlayDataReducer, {});

  useEffect(() => {
    const s = dispatchOverlayDataActionListener.subscribe(dispatchOverlayData);

    return () => {
      s.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const s = patchOverlayDataListener.subscribe((o) => {
      userCharacterService
        .patch(characterId, {
          overlayData: o,
          updated: Timestamp.now(),
        })
        .then()
        .catch(console.log);
    });

    return () => {
      s.unsubscribe();
    };
  }, [characterId, userCharacterService]);

  useEffect(() => {
    const s = onSnapshot(
      userCharacterService.getDoc(characterId),
      (snapshot) => {
        const data = snapshot.data() as UserCharacter | undefined;
        if (data && data.overlayData) {
          dispatchOverlayData({
            type: 'set-overlay-data',
            overlayData: data.overlayData,
          });
        }
      }
    );

    return () => {
      s();
    };
  }, [characterId, userCharacterService]);

  useEffect(() => {
    overlayDataListener.next(overlayData);
  }, [overlayData]);
}
