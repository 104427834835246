import { h } from 'preact';
import CollapseContent from '../../../../../helpers/collapse';
import Icon from '../../../../../helpers/icon';
import { UniqueKeyForm } from './unique-key-input';
import { UserListDisplayProps } from './user-list-types';
import styles from './displays.module.scss';

export function BooleanUserField({
  field,
  label,
  onChange,
}: UserListDisplayProps) {
  if (field.value.type !== 'boolean') {
    return null;
  }

  function _onChange(e: Event) {
    const target = e.target as HTMLInputElement;

    field.value.value = target.checked;

    onChange(field.value);
  }

  function onKeyChange(newKey: string) {
    field.key = newKey;

    onChange(field.value, field.key);
  }

  return (
    <div className={styles.userListItemContainer}>
      <CollapseContent
        trigger={
          <label className="form-label">
            <Icon icon="caret-down" size={18} /> Field Key: {field.key}
          </label>
        }
      >
        <UniqueKeyForm value={field.key} onChange={onKeyChange} />
      </CollapseContent>
      <div class="form-check form-switch">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="checkbox"
            checked={field.value.value}
            onChange={_onChange}
          />
          {label.text}
        </label>
      </div>
    </div>
  );
}
