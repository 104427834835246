import { Modal } from 'bootstrap';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, h, VNode } from 'preact';
import { createPortal } from 'preact/compat';
import { StateUpdater, useCallback } from 'preact/hooks';

const portal = document.getElementById('modals') as HTMLDivElement;

type PropTypes = {
  id: string;
  title: string;
  show?: boolean;
  setShow: StateUpdater<boolean>;
  backdropClose?: boolean;
  children?: VNode | VNode[];
  body?: VNode | VNode[];
  footer?: VNode | VNode[];
  class?: string;
  size?: 'modal-sm' | 'modal-lg' | 'modal-xl';
  fullscreen?:
    | 'modal-fullscreen'
    | 'modal-fullscreen-sm-down'
    | 'modal-fullscreen-md-down'
    | 'modal-fullscreen-lg-down';
  onClose?: () => void;
};

function shown(event: Event) {
  const node = event.target as HTMLDivElement;
  const el = node.querySelector('.focus');

  if (el) {
    (el as HTMLElement).focus();
  }
}

export default function Dialog(props: PropTypes) {
  const {
    id,
    title,
    children,
    body,
    footer,
    show,
    setShow,
    backdropClose = true,
    onClose,
  } = props;
  const ref = useCallback(
    (node: HTMLDivElement | null) => {
      function hide(e: MouseEvent) {
        if (e.target === node && backdropClose) {
          close();
        }
      }

      function close() {
        setShow(false);
        onClose?.();
      }

      if (node) {
        const modal = new Modal(node, {
          backdrop: false,
        });

        node.addEventListener('show.bs.modal', shown);
        node.addEventListener('hide.bs.modal', close);
        node.addEventListener('click', hide);

        modal.show();
      }

      return () => {
        node?.removeEventListener('show.bs.modal', shown);
        node?.removeEventListener('hide.bs.modal', close);
        node?.removeEventListener('click', hide);
      };
    },
    [backdropClose, setShow, onClose]
  );

  function closeModal() {
    const modal = Modal.getInstance(id);
    modal?.hide();
    setShow(false);
    onClose?.();
  }

  return createPortal(
    <AnimatePresence>
      {show && (
        <motion.div
          ref={ref}
          className={`modal ${props.class}`}
          id={id}
          aria-labelledby="modalLabel"
          aria-hidden="true"
          initial={{
            background: 'rgba(0,0,0,0)',
            display: 'block',
          }}
          animate={{
            background: 'rgba(0,0,0,0.3)',
          }}
          exit={{
            background: 'rgba(0,0,0,0)',
          }}
        >
          <motion.div
            className={`modal-dialog modal-dialog-scrollable modal-dialog-centered ${props.size} ${props.fullscreen}`}
            initial={{
              opacity: 0,
              scale: 0.9,
            }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            exit={{
              opacity: 0,
              scale: 0.9,
            }}
            transition={{
              type: 'spring',
              bounce: 0.25,
            }}
          >
            <div class="modal-content">
              <div className="modal-header">
                <h5 class="modal-title" id="modalLabel">
                  {title}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                />
              </div>

              {body && footer && (
                <Fragment>
                  <div className="modal-body">{body}</div>
                  <div className="modal-footer">{footer}</div>
                </Fragment>
              )}

              {children && children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    portal
  );
}
