import { useEffect, useState } from 'preact/hooks';
import { BehaviorSubject, Observable } from 'rxjs';

export function useBehaviorSubject<T>(subject: BehaviorSubject<T>) {
  const [state, setState] = useState<T>(subject.getValue());

  useEffect(() => {
    const s = subject.subscribe((value) => {
      setState(value);
    });

    return () => s.unsubscribe();
  }, [subject]);

  return state;
}

export function useObservable<T>(
  observable: Observable<T>,
  defaultValue: T | undefined = undefined
) {
  const [state, setState] = useState<T | undefined>(defaultValue);

  useEffect(() => {
    const s = observable.subscribe((value) => {
      setState(value);
    });

    return () => s.unsubscribe();
  }, [observable]);

  return state;
}
