import { motion, Variants } from 'framer-motion';
import { h } from 'preact';
import { useRef } from 'preact/hooks';

const pageVariants: Variants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  out: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
};

export default function BasePage(props: any) {
  const ref = useRef<HTMLDivElement>();

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      ref={ref}
      {...props}
    >
      {props.children}
    </motion.div>
  );
}
