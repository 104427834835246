import BasePage from '../../../helpers/page';
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import {
  ISheetVersionDisplay,
  SheetVersion,
} from '../../../../types/database/sheet-version';
import SheetVersionService from '../../../services/sheet-version-service';

import styles from './versions.module.scss';
import { Link } from 'preact-router';
import { orderBy, query, getDocs } from 'firebase/firestore';

interface PropTypes {
  id: string;
}

export default function VersionList(props: PropTypes) {
  const [versions, setVersions] = useState<ISheetVersionDisplay[]>([]);

  useEffect(() => {
    const sheetVersionService = new SheetVersionService(props.id);

    const q = query(sheetVersionService.find(), orderBy('updated', 'desc'));

    getDocs(q).then((data) => {
      const _versions = data.docs.map((doc) => ({
        doc,
        sheetVersion: doc.data() as SheetVersion,
      }));

      setVersions(_versions);
    });
  }, [props.id]);

  return (
    <BasePage {...props} className={styles.versionPage}>
      <div />
      <section className={styles.versionList}>
        {versions.map((data) => (
          <div key={data.doc.id} className="card">
            <div class="card-header bg-light bg-gradient text-light">
              <Link
                href={`/character-sheets/sheet-editor/${props.id}/${data.doc.id}`}
              >
                {data.sheetVersion.created.toDate().toLocaleDateString()}
              </Link>
            </div>
            <div className="card-body">
              {data.sheetVersion.version}
              <br />
              {data.sheetVersion.patchNotes}
            </div>
          </div>
        ))}
      </section>
    </BasePage>
  );
}
