import {
  AnimatePresence,
  LayoutGroup,
  motion,
  Transition,
} from 'framer-motion';
import { h, VNode } from 'preact';
import { route } from 'preact-router';
import { useMemo } from 'preact/hooks';
import { Fragment } from 'react';
import { Template } from '../../../../../types/database/sheet-version';
import Loading from '../../../../helpers/loading';
import { useObservable } from '../../../../helpers/observable-hook';
import BasePage from '../../../../helpers/page';
import { overlayDataListener } from '../../../game-grid/sheet/use-overlay-data';
import { sheetListener, useSheet } from '../grid-sort';
import { getPageName } from '../helpers';
import { useCharacterSheet } from '../hooks';
import { SettingsList } from '../settings';
import { AddUserListItem, UserListItem } from '../user-list/user-list-editor';
import { DisplayRenderer } from './displays/display-renderer';
import styles from './index.module.scss';

interface PropTypes {
  id: string;
  version: string;
  tab?: string;
}

export function Preview({ id, version, tab, ...rest }: PropTypes) {
  const { characterSheet } = useCharacterSheet(id);
  const sheet = useObservable(sheetListener);
  useSheet(id, version);
  const header = sheet?.pages.header;

  if (!sheet || !characterSheet || !header) {
    return <Loading />;
  }

  const page = tab && tab !== 'header' ? sheet.pages[tab] : undefined;

  return (
    <BasePage {...rest} className={styles.preview}>
      <motion.div layout className={styles.header}>
        <HeaderRenderer header={header} />
        <Tabs
          baseUrl={`/character-sheets/preview/${id}/${version}`}
          tab={tab}
          sheet={sheet}
        />
      </motion.div>
      {page && <PageRenderer page={page} />}
    </BasePage>
  );
}

interface HeaderRendererProps {
  header: Template.Page;
}
export function HeaderRenderer({ header }: HeaderRendererProps) {
  const layouts = useMemo(
    () =>
      Object.entries(header.layouts).sort(([, a], [, b]) => a.index - b.index),
    [header.layouts]
  );

  return (
    <motion.div className={styles.headerPage}>
      <AnimatePresence>
        <LayoutGroup>
          {layouts.map(([key, layout]) => (
            <BaseLayout key={key} column={layout.column}>
              <LayoutRenderer page={header.id} layout={layout} />
            </BaseLayout>
          ))}
        </LayoutGroup>
      </AnimatePresence>
    </motion.div>
  );
}

const transition: Transition = {
  type: 'spring',
  bounce: 0.1,
};
interface PageRendererProps {
  page: Template.Page;
}
export function PageRenderer({ page }: PageRendererProps) {
  const layouts = useMemo(
    () =>
      Object.entries(page.layouts).sort(([, a], [, b]) => a.index - b.index),
    [page.layouts]
  );

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={page.id}
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={transition}
        className={styles.page}
      >
        <LayoutGroup>
          <AnimatePresence initial={false}>
            {page.page.type === 'container' &&
              layouts.map(([key, layout]) => (
                <BaseLayout key={key} column={layout.column}>
                  <LayoutRenderer page={page.id} layout={layout} />
                </BaseLayout>
              ))}
            {page.page.type === 'settings' && <SettingsList page={page} />}
          </AnimatePresence>
        </LayoutGroup>
      </motion.div>
    </AnimatePresence>
  );
}

interface BaseLayoutProps {
  column: Template.ColumnSpan;
  children: VNode;
}
function BaseLayout({ column, children }: BaseLayoutProps) {
  return (
    <div
      className={styles.layout}
      style={{
        gridTemplateColumns: `repeat(${column.spanH}, minmax(0,1fr))`,
        gridColumn: `span ${column.spanH}`,
        gridRow: `span ${column.spanV}`,
      }}
    >
      {children}
    </div>
  );
}

interface LayoutRendererProps {
  page: string;
  layout: Template.Layout;
}
function LayoutRenderer({ page, layout }: LayoutRendererProps) {
  const displays = useMemo(
    () =>
      Object.entries(layout.displays).sort(([, a], [, b]) => a.index - b.index),
    [layout.displays]
  );

  if (layout.config.type === 'user-list') {
    return <UserListRenderer page={page} layout={layout} />;
  }

  return (
    <LayoutGroup>
      <AnimatePresence>
        {displays.map(([key, display]) => (
          <DisplayRenderer
            key={key}
            page={page}
            layout={layout.id}
            display={display}
          />
        ))}
      </AnimatePresence>
    </LayoutGroup>
  );
}

function UserListRenderer({ page, layout }: LayoutRendererProps) {
  const overlayData = useObservable(overlayDataListener);
  const data = overlayData?.[layout.id];
  const values = useMemo(() => {
    if (data?.type === 'user-list') {
      return data.values;
    }

    if (layout.config.type === 'user-list') {
      return layout.config.values;
    }

    return null;
  }, [data, layout]);

  if (layout.config.type !== 'user-list') {
    return null;
  }

  return (
    <Fragment>
      <div
        className={styles.userListTitle}
        style={{
          gridArea: `span  1 / span ${layout.column.spanH}`,
        }}
      >
        <h4>{layout.config.name}</h4>
        <AddUserListItem page={page} layout={layout} />
      </div>
      <LayoutGroup>
        {values &&
          Object.entries(values)
            .sort(([, a], [, b]) => {
              return b.index - a.index;
            })
            .map(([key, value]) => (
              <UserListItem
                key={key}
                userListItem={value}
                layout={layout}
                page={page}
              />
            ))}
      </LayoutGroup>
    </Fragment>
  );
}

interface TabsProps {
  baseUrl: string;
  tab?: string;
  sheet: Template.Sheet;
}
export function Tabs({ baseUrl, tab, sheet }: TabsProps) {
  const pages = useMemo(
    () =>
      Object.entries(sheet.pages)
        .sort(([, a], [, b]) => a.index - b.index)
        .filter(([_, each]) => each.page.type !== 'header'),
    [sheet]
  );

  const page = tab ? sheet.pages[tab] : undefined;
  if (!page) {
    const first = pages[0]?.[1];
    if (!first) {
      return null;
    }

    route(`${baseUrl}/${first.id}`);
    return null;
  }

  return (
    <div className={`${styles.tabs} nav nav-pills`}>
      {pages.map(([key, each]) => (
        <div className="nav-item item" key={key}>
          <motion.a
            href={`${baseUrl}/${each.id}`}
            layout
            className={`${styles.tab} nav-link`}
            active={key === page?.id}
          >
            {getPageName(each)}
            {key === page?.id && (
              <motion.div layoutId="underline" className={styles.active} />
            )}
          </motion.a>
        </div>
      ))}
    </div>
  );
}
