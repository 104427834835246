import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { h } from 'preact';

interface FormikFields {
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
}

export function ErrorDisplay({ touched, errors }: FormikFields, field: string) {
  return (
    <AnimatePresence>
      {touched[field] && errors[field] ? (
        <motion.div
          layout
          class="invalid"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          title={errors[field]}
        >
          <span class="text-light badge bg-danger">{errors[field]}</span>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

export function isFieldValid({ touched, errors }: FormikFields, field: string) {
  return touched[field] ? (errors[field] ? 'is-invalid' : 'is-valid') : '';
}
