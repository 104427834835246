import { cloneElement, RefObject } from 'preact';

function callRef(ref: any, arg: any) {
  if (typeof ref == 'function') ref(arg);
  else if (ref) ref.current = arg;
}

export function injectProxyRef(vnode: any, myRef: RefObject<HTMLElement>) {
  const ref = vnode.props.ref || vnode.ref; // safer
  return cloneElement(vnode, {
    ref: (c: any) => {
      callRef(ref, c);
      callRef(myRef, c);
    },
  });
}
