import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from '@firebase/storage';
import { storage } from '../firebase';
import { compress } from '../helpers/compress';

export class StorageService {
  async put(
    path: string,
    data: Blob,
    options: Omit<Compressor.Options, 'success' | 'error'> = { quality: 0.6 }
  ) {
    const file = await compress(data, options);
    return uploadBytes(ref(storage, path), file);
  }

  get(path: string): Promise<string> {
    return getDownloadURL(ref(storage, path));
  }

  delete(path: string) {
    return deleteObject(ref(storage, path));
  }
}

export default new StorageService();
