import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';
import { Template } from '../../../../../types/database/sheet-version';
import Dialog from '../../../../helpers/dialog';
import { generateId } from '../../../../helpers/generateId';
import Icon from '../../../../helpers/icon';
import { Menu, MenuItem } from '../../../../helpers/menu';
import { useObservable } from '../../../../helpers/observable-hook';
import {
  dispatchOverlayDataActionListener,
  overlayDataListener,
} from '../../../game-grid/sheet/use-overlay-data';
import { dispatchSheetActionListener } from '../grid-sort';
import { DisplayRenderer } from './displays/display-renderer';
import styles from './index.module.scss';

interface UserListModalProps {
  page: string;
  layout: Template.Layout;
}
export function UserListModal({ page, layout }: UserListModalProps) {
  const [show, setShow] = useState(false);

  return (
    <Fragment>
      <AnimatePresence initial={false}>
        {layout.config.type === 'user-list' && (
          <motion.button
            layout
            initial={{ opacity: 0, scale: 0.9, y: 8 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.9, y: 8 }}
            transition={{
              type: 'spring',
              duration: 0.4,
              delay: 0.1,
            }}
            className="btn btn-dark"
            onClick={() => {
              setShow(true);
            }}
          >
            Add Default User List Values
          </motion.button>
        )}
      </AnimatePresence>
      <Dialog
        key={layout.id}
        id="userListModal"
        title="User List Editor"
        size="modal-lg"
        fullscreen="modal-fullscreen-lg-down"
        show={show}
        setShow={setShow}
        body={<UserListModalBody page={page} layout={layout} />}
        footer={
          <button
            className="btn btn-secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </button>
        }
      />
    </Fragment>
  );
}

interface UserListModalBodyProps {
  page: string;
  layout: Template.Layout;
}
export function UserListModalBody({ page, layout }: UserListModalBodyProps) {
  if (layout.config.type !== 'user-list') {
    return null;
  }

  return (
    <div className={styles.userListModal}>
      <div>
        <AddUserListItem page={page} layout={layout} />
      </div>
      <div className={`list-group list-group-flush`}>
        <LayoutGroup>
          <AnimatePresence initial={false}>
            {layout.config.values &&
              Object.entries(layout.config.values)
                .sort(([, a], [, b]) => {
                  return b.index - a.index;
                })
                .map(([key, value]) => (
                  <motion.div
                    key={key}
                    className={`${styles.userListItem}  list-group-item`}
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                      originX: 0.5,
                      originY: 0,
                      gridTemplateColumns: `repeat(${layout.column.spanH}, minmax(0,1fr))`,
                    }}
                  >
                    <UserListItem
                      userListItem={value}
                      layout={layout}
                      page={page}
                    />
                  </motion.div>
                ))}
          </AnimatePresence>
        </LayoutGroup>
      </div>
    </div>
  );
}

interface AddUserListItemProps {
  page: string;
  layout: Template.Layout;
}

export function AddUserListItem({ page, layout }: AddUserListItemProps) {
  const overlayData = useObservable(overlayDataListener);

  if (layout.config.type !== 'user-list') {
    return null;
  }

  function add() {
    if (layout.config.type !== 'user-list') {
      return null;
    }

    if (!layout.config.values) {
      layout.config.values = {};
    }

    const id = generateId();
    const base: Template.UserList['values'] = {};
    const userList: Template.UserList = {
      id,
      enabled: false,
      label: {
        text: '',
        tooltip: '',
        style: {
          fontSize: 14,
          fontWeight: 'normal',
        },
      },
      index: Object.values(layout.config.values ?? {}).length,
      values: Object.values(layout.displays).reduce((acc, display) => {
        const _id = generateId();

        acc[_id] = {
          id: _id,
          displayId: display.id,
          key: generateId(),
          value: display.config,
        };

        return acc;
      }, base),
    };

    if (overlayData) {
      let list = overlayData[layout.id];

      if (!list) {
        list = {
          type: 'user-list',
          values: {
            [id]: userList,
          },
        };
      } else if (list.type === 'user-list') {
        list.values[id] = userList;
      } else {
        return;
      }

      dispatchOverlayDataActionListener.next({
        type: 'set-user-list',
        layout: layout.id,
        value: list.values,
      });
      return;
    }

    layout.config.values[id] = userList;

    dispatchSheetActionListener.next({
      type: 'update-layout',
      page,
      layout,
    });
  }

  return (
    <button className="btn btn-primary" onClick={add}>
      Add <Icon icon="plus" size={20} />
    </button>
  );
}

interface UserListItemProps {
  userListItem: Template.UserList;
  layout: Template.Layout;
  page: string;
}
export function UserListItem({
  userListItem,
  layout,
  page,
}: UserListItemProps) {
  return (
    <Fragment>
      <UserListItemHeader
        page={page}
        layout={layout}
        userListItem={userListItem}
      />
      {Object.entries(userListItem.values)
        .sort(([, a], [, b]) => {
          const displayA = layout.displays[a.displayId];
          const displayB = layout.displays[b.displayId];

          if (!displayA || !displayB) {
            return 0;
          }

          return displayA.index - displayB.index;
        })
        .map(([key, value]) => {
          const display = layout.displays[value.displayId];

          if (!display) {
            return null;
          }

          return (
            <DisplayRenderer
              key={key}
              page={page}
              layout={layout.id}
              display={display}
              item={userListItem}
              field={value}
            />
          );
        })}
    </Fragment>
  );
}

function UserListItemHeader({ page, layout, userListItem }: UserListItemProps) {
  const overlayData = useObservable(overlayDataListener);

  const toggleBtnTitle = (
    <button class={`btn btn-secondary ${styles['editor-dropdown']}`}>
      <Icon size={22} icon="list" />
    </button>
  );

  function removeListItem() {
    if (overlayData) {
      dispatchOverlayDataActionListener.next({
        type: 'remove-user-list-row',
        layout: layout.id,
        userListItem,
      });
    } else {
      dispatchSheetActionListener.next({
        type: 'remove-user-list-row',
        page,
        layout: layout.id,
        userListItem,
      });
    }
  }

  return (
    <motion.div layout className={styles.userListItemHeader}>
      <div className="form-check">
        <input
          id={userListItem.id}
          type="checkbox"
          className="form-check-input"
          checked={userListItem.enabled}
          onChange={(event) => {
            const target = event.target as HTMLInputElement;

            userListItem.enabled = target.checked;

            if (overlayData) {
              dispatchOverlayDataActionListener.next({
                type: 'set-user-list-row',
                layout: layout.id,
                userList: userListItem,
              });
              return;
            }

            dispatchSheetActionListener.next({
              type: 'update-user-list-row',
              layout: layout.id,
              page,
              userListItem,
            });
          }}
        />
        <label for={userListItem.id} className="form-check-label">
          Enabled
        </label>
      </div>

      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="List Item Title"
          value={userListItem.label?.text}
          onBlur={(event) => {
            const target = event.target as HTMLInputElement;
            if (!userListItem.label) {
              userListItem.label = {
                text: '',
                tooltip: '',
                style: {
                  fontSize: 14,
                  fontWeight: 'normal',
                },
              };
            }
            userListItem.label.text = target.value;
            if (overlayData) {
              dispatchOverlayDataActionListener.next({
                type: 'set-user-list-row',
                layout: layout.id,
                userList: userListItem,
              });
              return;
            }
            dispatchSheetActionListener.next({
              type: 'update-user-list-row',
              layout: layout.id,
              page,
              userListItem,
            });
          }}
        />
      </div>

      <Menu
        id={`dropdown-${layout.id}`}
        autoClose="both"
        trigger={toggleBtnTitle}
      >
        <MenuItem onClick={removeListItem}>Delete</MenuItem>
      </Menu>
    </motion.div>
  );
}
